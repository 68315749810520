:root {
  --button-background-color: #2c7be5;
  --font-work-sans: "Work Sans", sans-serif;
}
.login-form {
  margin-top: 15px !important;
  width: 450px;
}
.login-form .ant-row {
  display: block;
  margin-left: 30px;
  margin-right: 25px;
}
.left-login-block {
  margin-top: auto;
  margin-bottom: auto;
  text-align: -webkit-center;
}

.right-login-block {
  margin-top: 100px;
}

.login-btn-color {
  background-color: var(--button-background-color);
}

.login-btn-color:hover {
  border-color: var(--button-background-color);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--button-background-color);
}

.login-form-forgot {
  color: var(--button-background-color);
}
.login-form-forgot:hover {
  color: var(--button-background-color);
}

.welcome-text {
  color: #263446;
  font-size: 20px;
  font-weight: 600;
  display: grid;
  text-align: left;
}

.login-text {
  color: #5e81f4;
  font-size: 40px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 20px;
}

.login-input {
  line-height: 40px;
  border: 0px;
  width: 100%;
  height: 42px !important;
  border-radius: 4px !important;
}
.for-rem {
  margin-top: -5%;
  margin-left: -7px;
}
.for-title {
  font-size: 24px;
  width: 100%;
  /* height: 30px; */
  padding-top: 20px;
  /* margin-left: 15px; */
}
.for-but {
  width: 50%;
}
.for-forget {
  margin-top: -5%;
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.for-user {
  margin-left: -45%;
  width: 125%;
  height: 48px !important;
}
.for-dont {
  margin-left: 15px;
}
.for-pass {
  margin-left: -32%;
  width: 115%;
}
.do-you .ant-form-item-control-input-content {
  text-align: center;
  margin-top: 20px;
}

.for-logo {
  top: 166px;
  left: 1004px;
  margin-top: 50px;
}

.for-img {
  margin-top: 60px;
  margin-right: 62px;
  margin-bottom: 59px;
  margin-left: 61px;
}
.do-you-text {
  color: #b5b6b6;
  margin-right: 10px;
}
.ant-form-item-label label {
  padding-top: 10px !important;
  font-family: var(--font-work-sans);
  color: #718096;
  font-size: 15px;
  font-weight: 600;
  opacity: 90%;
}

.log_form_main {
  background-color: white;
  width: 450px;
  margin-top: 5%;
  height: 77%;
  margin-left: auto !important;
  margin-right: auto !important;
  border-radius: 16px;
  box-shadow: 0px 6px 58px 0px #c4cbd61a;
}
.login_logo {
  margin-left: auto;
  margin-right: auto;
}
.sub_title {
  font-size: 16px;
  color: #718096;
}
.login-input .ant-input {
  margin-top: 0px;
  margin-left: 5px;
  font-size: 15px;
}
.ant-input-prefix .site-form-item-icon {
  font-size: 20px;
  color: #7d8592;
}
.ant-input-suffix .site-form-item-icon {
  font-size: 20px;
  color: #7d8592;
}
.login-form-button {
  height: 45px;
  font-size: 16px;
  border-radius: 4px;
}
.text-right .ant-form-item-control-input-content {
  margin-top: -25px;
}

.login_alerts {
  margin-left: 30px;
  margin-right: 25px;
  margin-top: 15px;
}
.ant-form-item-explain.ant-form-item-explain-error {
  margin-top: -15px;
  margin-bottom: 15px;
}
.ant-form-item-label > label::after {
  content: "";
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.show_pass {
  cursor: pointer;
}
.m-t--20 {
  margin-top: -16px;
}
.login_alerts .close {
  font-size: 2.3rem;
  font-weight: 350;
  margin-top: 3px;
}
input.new-pass-inp-pad {
  padding-left: 15px;
}
.setup-pass-eye-icon {
  margin-left: -32px;
  font-size: 18px !important;
  margin-bottom: 7px;
}
