/* @font-face {
  font-family: "inter-regular";
  src: local("inter-regular"),
    url(./assets/fonts/inter/static/Inter-Regular.ttf) format("truetype");
} */
body {
  /* font-family: "inter-regular" !important; */
  background-color: #f1f5f8 !important;
  min-width: 1024px !important;
}
.MuiBackdrop-root {
  z-index: 1 !important;
  background-color: #fafafa !important;
}
