@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@500&display=swap");

:root {
  --button-background-color: #2c7be5;
  --background-color: #005fb2;
  --font-work-sans: "Work Sans", sans-serif;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"),
    url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff)
      format("woff");
}
.search-pat {
  padding-bottom: 15px;
  padding-top: 10px;
}
.people {
  /* color: #718096; */
  font-size: 20px;
}
#datetime-local {
  color: #2d3748;
  padding-bottom: 10px;
}
.all-day {
  color: #2d3748;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
}
.fv-ph-icon {
  cursor: pointer;
  transition-duration: 0.3s;
}
.fv-ph-icon:hover {
  background-color: lightgray;
  cursor: pointer;
}
.grp-btn {
  background-color: white;
  color: black;
  border: 1px solid #d9d9d9;
}
.marg {
  margin-left: 32px !important;
}
.alx {
  padding-bottom: 10px;
}
.calender-popup .modal-dialog {
  /* margin-right: 250px !important; */
}
.calender-popup .modal-content {
  width: 593px !important;
  /* right: 245px !important; */
  /* height: 671px; */
}
.calender-popup .c-app {
  padding: 20px;
}
.video-call-btn {
  width: 32px !important;
  height: 32px !important;
  padding: 2px;
}
.m-l-12 {
  margin-left: 12px !important;
}
.p-l-19 {
  padding-left: 19px !important;
}
.p-r-20 {
  padding-right: 20px !important;
}
.p-t-16 {
  padding-top: 16px !important;
}
.modal-footer {
  border-top: 1px solid #cbd5e0 !important;
}
.tabname .ant-tabs-nav {
  margin: 0px !important;
}
.tabname .ant-radio-group {
  padding-left: 30px !important;
}
.f-w-400 {
  font-weight: 400;
}
.btn-n {
  border: none !important;
  align-items: center !important;
  padding: 0px;
}
.btn-n:hover {
  background-color: #ffffff;
  color: var(--button-background-color);
}
.b-l-none {
  border-right: 0px solid white !important;
  border-left: 0px solid white !important;
  border-top: 1px solid #cbd5e0 !important;
  border-bottom: 0px solid white !important;
}
.bbn-align {
  padding-top: 16px;
  float: right;
  padding-bottom: 16px;
  margin-bottom: 4px;
  margin-top: 0px;
  padding-top: 6px;
}
.count-bo {
  padding-right: 16px;
  width: 100%;
}
.iconwith {
  width: 100px;
}
.appoint-font {
  font-size: 14px;
  font-weight: 400;
  color: #718096;
}
.Un-invoiced {
  color: #b6351c;
  font-size: 14px;
  font-weight: 400;
  margin-top: 16px !important;
}
.creat-link {
  padding-left: 18px;
  color: var(--button-background-color);
  font-size: 14px;
  font-weight: 400;
}
.pat-balance {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
}
.invoice-paid span {
  background-color: #57bf7f;
  color: #ffffff;
  border-radius: 16px;
  padding: 2px 20px 2px 20px;
  font-family: var(--font-work-sans);
  font-style: normal;
  font-size: 12px;
}
.invoice-paid {
  padding-left: 19px;
}
.invoice-unpaid {
  padding-left: 19px;
}
.invoice-unpaid span {
  background-color: #fb5656;
  color: #ffffff;
  border-radius: 16px;
  padding: 2px 12px 2px 12px;
  font-family: var(--font-work-sans);
  font-style: normal;
  font-size: 12px;
}
.service-main-div .MuiTableRow-root td {
  font-size: 12px !important;
}
.service-main-div .MuiTableContainer-root {
  overflow-x: unset !important;
}
.service-main-div .MuiTableRow-root td button {
  font-size: 12px !important;
}
.services_total {
  display: flex !important;
  padding: 20px 0;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;
  /* border: 1px solid #cbd5e0; */
}
.services_total1 {
  display: flex !important;
  align-items: center;
  padding-left: 12px;
}

.services_total2 {
  display: flex !important;
  margin-left: auto;
  margin-right: 10px;
}
.service-main-div .prob-head-title {
  font-size: 16px !important;
}
.dollor-value {
  color: #2d3748;
  font-size: 16px;
  font-weight: 500;
}
.dollor-name {
  font: 12px;
  font-weight: 400;
  color: #718096;
}
.dollor-symbol {
  height: 32px;
  border-right: none;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px 0px 0px 4px;
}
.frm-apoint {
  text-align: initial;
  color: #718096;
  width: 62%;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 6px;
}
.apt-det-date-time-picker {
  margin-left: 11px !important;
}
.apt-det-date-time-picker .ant-picker {
  border: 1px solid rgba(46, 46, 46, 0.25) !important;
}
.drop-svg .MuiSelect-icon {
  display: none;
}
.footer-border-none {
  border-top: none !important;
  padding-top: 0px;
}

.e-quick-popup-wrapper {
  margin-left: 94% !important;
}
.e-header-icon-wrapper {
  z-index: 1000;
}
.MuiInputLabel-shrink {
  display: none !important;
}
.we-none {
  /* -webkit-appearance: none; */
  width: 260px;
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 5px;
  color: #718096;
  font-size: 12px;
}
.we-none12:hover {
  background-color: #f1f3f4 !important;
}
.we-none12:focus {
  outline: none !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  transition: border 0.2s;
}
.new_appt_detail .we-none12 {
  background-color: #f1f3f4 !important;
  margin-left: 15px;
  height: 32px;
  padding: 0px 6px;
  margin-right: 3px;
}
.we-none1 {
  /* -webkit-appearance: none; */
  width: 300px;
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 5px;
  color: #718096;
  font-size: 14px;
}
.e-input-group:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left) {
  border-bottom: 1px solid #d9d9d9 !important;
}
#fullscreen-popup1 {
  width: 100% !important;
}

#fullscreen-popup {
  width: 100vw !important;
  max-width: none !important;
  height: 100% !important;
  margin: 0 !important;
}

#fullscreen-popup .modal-content {
  height: auto !important;
  border: 0 !important;
  border-radius: 0 !important;
  background-color: #f1f5f8;
}

.closebtn {
  margin-right: auto;
}
/* .modal-header{
  padding-left: 296px;
} */

.modal-content {
  width: 100% !important;
  background-color: #ffffff;
  border: none !important;
}
.modal-header .close {
  padding: 1rem 1rem;
  /* float: left; */
  font-size: 45px;
  opacity: 3;
  margin: 0;
}
.modal-head-conten {
  padding: 1rem 1rem;
  margin: 1rem 1rem 1rem auto;
  display: none;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-siz {
  font-size: 25px;
}
.icon-siz1 {
  font-size: 17px;
  margin-top: 10px;
}
.btn-gray {
  color: #212529;
  background-color: #f6f7f9;
  border-color: #f6f7f9;
}
#fullscreen-popup .modal-header1 {
  display: block;
  font-size: 25px;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  background-color: #ffffff;
}
.invoice-comp {
  margin-left: auto;
  margin-right: auto;
}
.modal-bg {
  background-color: #cce2ff;
}
#fullscreen-popup span {
  padding-left: 28px;
  color: #718096;
}
.hr-line {
  border-left: 3px solid #cbd5e0;
  height: 50px;
}
.invoice-name {
  margin-top: auto;
  margin-bottom: auto;
}
.invoice-name {
  font-size: 18px;
  font-weight: bold;
  margin-left: 92px;
  position: absolute;
  margin-top: 14px;
}
.h-icon {
  color: #718096;
}
#new-appts .ui.icon.input > input {
  border: none;
  padding-left: 10px !important;
}
#new-appts .ui.search:hover {
  background-color: #f1f3f4;
  border-radius: 4px;
}
.new_appt_detail .ui.search {
  background-color: #f1f3f4;
  border-radius: 4px;
}
#new-appts .ui.search {
  width: 285px;
}

#new-appts .result:hover {
  background-color: #f9fafb !important;
}
#new-appts .result:hover > .content {
  background-color: #f9fafb !important;
}
/* #container:hover > #cube { background-color: yellow; }
#new-appts .content:hover{
  background-color:#f4f4f4 !important;
} */

#new-appts .input:focus-within {
  border-radius: 3px;
  background-color: #f1f3f4;
  border-bottom: 3px solid #2168f7;
}
.we-none12:focus-within {
  border-radius: 3px;
  background-color: #f1f3f4 !important;
  border-bottom: 3px solid #2168f7 !important;
}
#new-appts input:focus-within {
  box-shadow: none;
}
.drop-svg {
  padding-right: 12px;
}
#new-appts .input {
  width: 285px;
}

#new-appts .ui.icon.input > i.icon {
  display: none;
}

#new-appts .modal-title {
  margin: 13px 0px 13px 27px;
}
#new-appts .search-pat {
  padding: 0px 0px 10px 0px;
}
.repeats .we-none {
  width: 98px;
}
.rep-date .ant-picker {
  border-radius: 4px;
}
.rep-date .w-100 {
  width: 160px !important;
  margin: 4px 6px 11px 7px;
}
.new-appts .MuiFormControlLabel-root {
  margin-left: 9px !important;
}
.new-appts .all-day {
  margin-left: 0px !important;
}

.repeats {
  margin: 16px 0px 13px 0px;
  font-size: 14px;
  display: flex;
}
.week-btn button {
  height: 35px;
  width: 40px;
}
.hr-tag {
  width: 400;
}
.MuiFormGroup-row {
  margin: 10px 0px 10px 0px;
}
/* .app-popup .modal-dialog {
  left: 940px !important;
  top: 4px !important;
} */

.app-popup .modal-header .close {
  font-size: 2rem !important;
  margin: -3px 0px 0px 310px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.date-pick .ant-picker {
  border: none;
  width: 195px;
  margin: 0px 0px 0px 1px;
}

.date-pick .ant-picker:hover {
  background-color: #f1f3f4;
}
.new_appt_detail .date-pick .ant-picker {
  background-color: #f1f3f4;
}
.date-pick .ant-picker-focused:focus-within {
  border-radius: 3px;
  background-color: #f1f3f4;
  box-shadow: none;
  border-bottom: 3px solid #2168f7;
}
.date-pick1 .ant-picker:hover {
  background-color: #f1f3f4;
}
.new_appt_detail .date-pick1 .ant-picker {
  background-color: #f1f3f4;
}
.date-pick1 .ant-picker-focused:focus-within {
  border-radius: 3px;
  background-color: #f1f3f4;
  box-shadow: none;
  border-bottom: 3px solid #2168f7;
}

.date-pick1 .ant-picker {
  border: none;
  width: 150px;
  padding: 0px 8px 0px;
}
.date-pick1 {
  display: flex;
  padding-left: 5px;
}
.date-pick .anticon {
  display: none;
}
.date-pick1 .anticon {
  display: none;
}
.date-pick {
  display: flex;
}

.date-pick .timepick {
  width: 100px;
}
#new-app-duration {
  border: 0px;
  text-align: center;
  border-radius: 4px;
}

.all-day1 .MuiTypography-root {
  margin: 0px 16px;
}
.all-day1 {
  width: 36px;
}
.marg .MuiGrid-item {
  margin: 0px 10px;
}
.marg .btn-toolbar {
  margin: 0px 5px;
}
.rep-date {
  margin-left: -6px;
}
.space-border:focus-within {
  margin-bottom: 7px;
  padding-bottom: 8px;
  margin-top: -2px;
  padding-top: 15px;
  border-color: #cbd5e0 !important;
  /* border-bottom: 1px solid; */
  border-top: 1px solid;
  width: 379px;
  /* border-width: 1px; */
  padding-left: -15px;
}
.border0 {
  /* margin-bottom: 7px; */
  /* padding-bottom: 8px; */
  margin-top: -2px;
  padding-top: 15px;
  border-color: #cbd5e0 !important;
  /* border-bottom: 2px solid; */
  border-top: 1px solid;
  width: 379px;
  /* border-width: 2px; */
  padding-left: -15px;
}
.space-border2:focus-within {
  margin-bottom: 7px;
  /* padding-bottom: 8px; */
  margin-top: -2px;
  padding-top: 15px;
  border-color: #cbd5e0 !important;
  /* border-bottom: 1px solid; */
  border-top: 1px solid;
  /* border-width: 2px; */
  width: 379px;
}
.border2 {
  margin-bottom: 7px;
  /* padding-bottom: 8px; */
  margin-top: -2px;
  padding-top: 15px;
  border-color: #cbd5e0 !important;
  /* border-bottom: 1px solid; */
  border-top: 1px solid;
  /* border-width: 2px; */
  width: 379px;
}

.space-border1:focus-within {
  margin-bottom: 7px;
  /* padding-bottom: 8px; */
  margin-top: -2px;
  padding-top: 15px;
  border-color: #cbd5e0 !important;
  /* border-bottom: 1px solid; */
  border-top: 1px solid;
  /* border-width: 2px; */
  width: 379px;
}
.border1 {
  margin-bottom: 7px;
  /* padding-bottom: 8px; */
  margin-top: -2px;
  padding-top: 15px;
  border-color: #cbd5e0 !important;
  /* border-bottom: 1px solid; */
  border-top: 1px solid;
  /* border-width: 2px; */
  width: 379px;
}
#new-appts .modal-body {
  padding: 0px !important;
}
/* .space-border1 .MuiGrid-root{
  padding-left: 20px;
}
.space-border .MuiGrid-root{
  padding-left: 20px;
}.space-border2 .MuiGrid-root{
  padding-left: 20px;
} */
#new-appts .MuiFormGroup-root {
  padding-left: 27px;
}
#new-appts .MuiGrid-root {
  padding-left: 15px;
}
#new-appts .MuiGrid-root .MuiGrid-item {
  padding-left: 15px;
}
.event-all {
  padding-left: 9px;
}
.rep {
  margin-left: 0px;
}
.hp hr {
  margin-top: 0px !important;
  width: 375px;
}

.hide-hr {
  border-top: 0px !important;
  border: 0px;
  width: 507px;
}
.hide {
  width: 473px;
}
.hp .footer-border-none {
  margin: 0px 10px 4px !important;
}
/* @media (min-width:1200px) and (max-width:1900px) {
  .app-popup .modal-dialog {
    left: 680px !important;
    top: 7px !important;
}


} */
.app-popup .modal-dialog {
  max-width: 43% !important;
}
.hp .modal-dialog {
  /* position: absolute; */
  width: 380px;
  height: 555px;
  /* left: 855px; */
  top: 20px;
}
#lab-order .modal-header {
  padding: 20px !important;
}

.change-top-new-appt.hp .modal-dialog.modal-dialog-centered {
  top: 0px !important;
  height: max-content !important;
}
#add-med .modal-header {
  padding: 20px !important;
}
#add-med .modal-body {
  padding: 19px 20px 0px 20px;
}

#add-med .modal-footer {
  padding: 3px 20px 16px 20px !important;
  border-top: none !important;
}
#add-allergy .modal-header {
  padding: 20px !important;
}
#add-allergy .modal-footer {
  padding: 3px 20px 16px 20px !important;
  border-top: none !important;
}
#add-allergy .modal-body {
  padding: 19px 20px 0px 20px;
}
#add-prob .modal-header {
  padding: 20px !important;
}
#add-prob .modal-footer {
  padding: 7px 20px 16px 16px !important;
  border-top: none !important;
}
#add-prob .modal-body {
  padding: 19px 20px 0px 20px;
}
.em-check .ant-checkbox-wrapper {
  margin-top: 0px !important;
}

#add-facility .modal-header .close {
  margin-top: 8px;
}
/* .new_appt_flag {
  background-color: #e8f0fe;
  height: 34px;
  color: var(--button-background-color);
  border-radius: 4px;
  margin-left: 28px;
  padding: 4px 12px 0px 10px;
  margin-bottom: 17px;
}
.new_appt_flag_icon {
  font-size: 20px;
}
.new_appt_flag_amt {
  font-size: 16px;
  line-height: 20px;
  vertical-align: middle;
} */

.new_appt_flag {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px;
  border-radius: 5px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.new_appt_flag_icon {
  flex-shrink: 0;
  font-size: 18px;
}

.new_appt_flag_amt {
  font-size: 16px;
  line-height: 1.5;
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
}

.appt_color {
  color: #718096;
}
/* .selet_label {
  font-size: 16px;
  color: #718096;
  margin-left: 17px;
  margin-bottom: 15px;
  margin-top: -4px;
} */
.radio_appt_btn {
  color: #718096;
}
#new_appt_radio span.ant-radio + * {
  color: #718096;
}
#new_appt_radio span.ant-radio-checked + * {
  color: #000000;
}
#new_appt_radio {
  margin-left: 17px;
  margin-bottom: 15px;
}
.date_space {
  margin-left: 8px;
}
#new-appts .ant-checkbox-wrapper {
  margin-top: 0px !important;
}
.appt_repeats .btn-primary {
  color: #000000;
  background: none;
  border-color: #d9d9d9;
  width: 40px;
}
.appt_repeats .btn-primary:hover {
  background-color: var(--button-background-color);
}
.appt_repeats {
  margin-bottom: 10px;
}
.for-appt-repeats {
  margin-left: -6px !important;
}
.appt_det_left .ant-tabs-nav-wrap {
  margin-left: 20px;
}
.new_appt_dett .new_appt_flag {
  margin-top: 20px;
  margin-bottom: 15px;
  margin-left: 20px;
}
.new_appt_dett .MuiFormGroup-root {
  padding-left: 38px !important;
}
.new_appt_dett .MuiGrid-root .MuiGrid-item {
  padding-left: 8px !important;
}
.new_appt_dett .all-day {
  margin-left: 4px !important;
}
.m-b-8 {
  margin-bottom: 8px;
}
.all_repeats .MuiTypography-body1 {
  margin-left: 16px;
}
.event_all_det {
  padding-left: 2px;
}
.new-appt_det {
  overflow-x: hidden;
}
.bill_appt {
  height: 331px;
  /* overflow-x: hidden; */
  overflow: auto;
  padding: 0px 20px !important;
}
.bill_appt .ui.input > input {
  margin-right: 0px !important;
}
.bill-new1 {
  margin: 20px -20px !important;
}
.bill-new2 {
  margin: 0px -20px 6px -20px !important;
}
.bill-new3 {
  margin: 16px -20px 16px -20px !important;
}
.vital_tble {
  height: 395px;
  overflow-x: hidden;
  padding: 0px 20px !important;
}
.visible .visible {
  background: #000000;
  overflow-x: hidden;
  /* height: 150px; */
}

.client-popup-for-template .modal-dialog {
  height: 469px;
  width: 400px;
}
.client-popup-for-profiles .modal-dialog {
  height: 436px;
  width: 482px;
}
.client-popup-for-billing-profiles .modal-dialog {
  /* height: 1047px !important; */
  max-width: 1170px !important;
  width: 81%;
}
.client-popup-for-patientflag .modal-dialog {
  width: 482px;
  height: 261px;
}

.spl_til {
  color: var(--button-background-color);
  font-size: 20px;
  font-weight: 500;
}

.chk-text {
  margin-bottom: 0px;
  margin-top: 18px;
}
.chk-text-below {
  margin-bottom: 0px;
  margin-top: 18px;
}

.chk-right {
  width: 100%;
  text-align: right;
  margin-bottom: 0px;
}

.chk-row {
  border-bottom: 1px solid #ebeaed;
  padding-bottom: 12px;
}

/* ***********
 **** Task  Page 
 **************** */
.task-container {
  padding: 3px 24px 60px 25px;
  margin-right: 0px;
  background-color: #f4f6fa;
  width: 100%;
}
.task-main-block {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
  border-radius: 8px;
  padding: 24px 0px;
}
.task-top-layer {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 0px 40px;
}
.task-head-title {
  font-size: 28px;
  line-height: 38px;
  color: #2e2c34;
}
.add-task-btn {
  width: 145px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  padding-left: 6px;
  padding-top: 8px;
}
.add-temp-btn {
  height: 40px;
  margin-left: 28px;
}
.task-second-layer {
  background-color: #fcfcfc;
  width: 100%;
  height: auto;
  padding-left: 40px;
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.tk-flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.tk-head-edit-btn {
  background-color: white;
  border: none;
  color: var(--button-background-color);
}

div.task-second-layer * {
  border-radius: 4px;
}
.show-archive-btn {
  /* width: 120px; */
  height: 34px;
  font-size: 14px;
  line-height: 17px;
  color: var(--button-background-color);
  background: #fafafa;
  border: 1px solid #ebeaed;
}
/* .task-card-search-btn .ui.icon {
   margin-right: 30px; 
  width: 200px;
  height: 34px !important;
} */

.tk-sel-box.form-control {
  padding: 0px;
  color: #40cfe9;
  border: none;
  height: 32px;
  margin-bottom: 2px;
  font-size: 14px;
}
.tk-sel-box.form-control:hover {
  border: none;
  box-shadow: none;
}
.tk-sel-box.form-control:focus {
  border: none;
  box-shadow: none;
}
.tk-re-fil-btn {
  width: 115px;
  height: 34px;
  margin: 16px 0px 16px 0px;
}

/* ************
  Task Card page
  ************** */

/* .tk-cd-tabs{
  display: block;
} */
.tk-px-40 {
  padding: 0px 40px 0px 40px;
  height: 83.89%;
}
.tk-px-40 .ant-tabs-tab {
  padding: 0 0 12px !important;
}
.tk-cd-top-layer {
  padding: 0px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
}
.tk-cd-head-title {
  margin-top: 24px;
  display: flex;
  margin-bottom: 10px;
}
.vw-tk-back {
  display: none;
  margin-top: 2px;
  cursor: pointer;
}
.vw-tk-btn {
  border: 1px solid #ebeaed;
  box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.07);
  background-color: white;
  border-radius: 4px;
  color: var(--button-background-color);
  margin-left: 14px;
}
span.title-text {
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #2e2c34;
}
.tk-cd-right-btn {
  margin: 24px 0px 0px 0px;
  color: var(--button-background-color);
  cursor: pointer;
  justify-self: flex-end;
}
.tk-btn-right-place {
  margin: 24px 0px 0px 0px;
  justify-self: flex-end;
}
.tasks-card-block {
  padding: 0;
  grid-template-columns: 22% 22% 22% 22%;
  column-gap: 3%;
}
.task-card {
  border-radius: 4px;
  background-color: #f4f6fa;
  width: 100%;
  height: 540px;
  margin: 26px 40px 0px 0px;
  border: 1px solid #cbd5e0;
}
.task-card-title {
  background: #ffffff;
  border-bottom: 1px solid #d0d9e3;
  box-shadow: 0px 0px 4px rgba(20, 46, 110, 0.1);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 8.15%;
  margin: 0%;
  display: flex;
  justify-content: space-between;
}
.task-card-body {
  /* opacity: 0.9; */
  overflow: auto;
  margin-top: 0;
  height: 480px;
  width: 100%;
}
.card-body-section {
  margin: 16px 5% 0px 5%;
  padding-top: 8px;
  padding-bottom: 10px;
  width: 90%;
  /* height: 210px; */
  background-color: white;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
  border-radius: 8px;
  position: relative;
  text-align: left;
  cursor: grab;
}
.card-body-section.dragging {
  opacity: 0.5;
}
.card-title-text {
  font-weight: 600;
  font-size: 16px;
  height: 20px;
  line-height: 18px;
  margin: 12px 0px 12px 16px;
  color: #2e2c34;
}
.card-title-number {
  font-weight: 600;
  margin: 12px 17px 12px 0px;
  height: 20px;
  width: 20px;
  font-size: 12px;
  color: #636d73;
  border-radius: 50%;
  background-color: #f4f6fa;
}
.cd-indcr-flag {
  margin-left: 12.67px;
  margin-right: 9.67px;
  height: 11px;
  margin-bottom: 12.33px;
}
.card-indicator-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 5px 8px 0px;
  /* font-weight: 500;
  font-size: 14px; */
  width: 100%;
  height: 20px;
  line-height: 20px;
}
.tk-cd-indctr {
  display: flex;
  align-items: center;
}
.cd-indcr-dots {
  padding: 0px;
  margin-right: 12px;
  /* margin-left: 175px;
  margin-bottom: 6px; */
  cursor: pointer;
}

.tk-cd-dot-icon {
  color: #84818a;
}
.task-card-btn.btn-primary.btn {
  border-radius: 4px;
  font-size: 12px;
  height: 24px;
  font-weight: 600;
  padding: 2px 6px 4px;
  line-height: 18px;
  border: none;
}
.lab-res-btn {
  background-color: rgba(0, 165, 255, 0.1);
  color: #00a5ff;
}
.task-date-btn {
  background: #636d73;
  color: white;
}
.tk-cd-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5%;
}
@media (min-width: 820px) and (max-width: 1300px) {
  .tk-cd-btn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (min-width: 820px) and (max-width: 1300px) {
  .lab-res-btn-place {
    margin-bottom: 10px;
  }
}
/* .lab-res-btn-place {
  position: absolute;
  left: 12px;
  bottom: 12px;
}
.date-btn-place {
  position: absolute;
  bottom: 12px;
  right: 7px;
} */

.provider-initial-orange {
  border-radius: 50%;
  background-color: orange;
  height: 24px;
  width: 24px;
  padding: 3px;
  color: white;
  line-height: 18px;
  margin: 8px 7px 12px 12px;
  font-size: 12px;
  font-weight: 600;
}
.provider-name-text {
  line-height: 20px;
  color: var(--button-background-color);
  margin: 12px 0px 15px 0px;
}
.card-simple-text {
  margin: 12px 0px 12px 12px;
  font-size: 12px;
  line-height: 20px;
  color: #2e2c34;
}
.task-card-date {
  margin: 14px 0px 17px 10px;
  color: #84818a;
  font-weight: 500;
  font-size: 12px;
  height: 18px;
  display: flex;
  align-items: center;
}

/* *************
  Add Task Modal 
  *************** */

.ed-tk-close,
.add-task-close {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: #f1f2f5;
}
.ed-tk-close {
  margin-left: 64.5%;
  margin-top: 5px;
}
.inv-ins-close {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #f1f2f5;
  float: left;
  margin: 16px 0px 0px 20px;
}
.ad-tk-lbl,
.cr-tk-lbl {
  margin: 26px 0px 0px 20px;
}
.ed-tk-lbl {
  margin: 18px 0px 0px 40px;
}
.ad-tk-inp,
.ad-tk-date-pick {
  margin: 20px 20px 0px 0px;
}
.ad-tk-inp-asg {
  margin: 20px 20px 0px 0px;
  display: inline-block;
  position: relative;
}
.ad-tk-inp-asg .form-control {
  width: 260px;
}
.ed-tk-inp,
.ed-tk-date-pick,
.cr-tk-inp {
  margin: 12px 40px 0px 0px;
}
.ad-tk-date-pick {
  width: 100%;
}
.ad-tk-date-pick .ant-picker {
  width: 260px;
}
.ad-tk-date-pick .form-control {
  width: 260px;
}
.ed-tk-date-pick .ant-picker {
  width: 304px;
}
.ad-tk-date-pick .ant-picker-suffix {
  padding-bottom: 5px;
}
.ed-tk-date-pick .ant-picker-suffix {
  padding-bottom: 5px;
}
.ad-tk-inp *,
.cr-tk-inp * {
  width: 260px;
}
.ed-tk-inp * {
  width: 304px;
}
#edit_tasks hr {
  margin-bottom: 0;
}
.tk-rel-item-add {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.tk-nt-text {
  color: var(--button-background-color);
  cursor: pointer;
}
.ad-tk-search-part {
  margin: 20px 40px;
}
.ad-tk-pat-srch {
  display: none;
  border-top: 1px solid #cbd5e0;
  align-items: center;
  padding: 10px 20px 10px 20px;
}
.rel-item-tag {
  padding-top: 8px;
  padding-left: 0px !important;
}
.ad-tk-pat-srch .ui.search {
  width: 270px;
}
.ad-tk-pat-srch .ui.icon.input {
  width: 100%;
}
.tk-md-foot {
  position: relative;
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tk-md-foot .tk-cancel-btn {
  width: 87px;
  height: 32px;
  background-color: #ecedef;
  font-size: 14px;
  margin-right: 24px;
}
.tk-md-foot .tk-save-btn {
  width: 73px;
  margin-right: 38px;
  background-color: var(--button-background-color);
  font-size: 14px;
  height: 32px;
  justify-self: flex-end;
}
.ct-ed-full {
  width: 500px;
  padding: 0;
}
.edct-first-cell {
  width: 230px;
}
.cat-md-title-txt {
  font-size: 18px;
  font-weight: 600;
}
.ct-ed-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 16px 16px 24px;
}
.ed-ct-tb-head {
  height: 40px;
  padding-left: 4px;
}
.ct-ed-inp,
.ct-ed-btn-place {
  display: inline-block;
}
.ct-ed-inp {
  margin: 15px 0px 8px 2px;
}
.ct-ed-btn {
  height: 38px;
  margin: 0px;
}
.ct-ed-btn-place {
  margin-left: 140px;
}

.st-ed-ftr {
  display: flex;
  align-items: center;
  height: 68px;
}
.st-ed-full {
  width: 654px;
}
.st-ed-inp1 {
  margin-left: -9px;
}
.task-edit-status .modal-dialog {
  max-width: 654px;
  max-height: 980px;
  height: auto;
}
.ed-st-buttons {
  display: flex;
  align-items: center;
}
.st-ed-btn {
  width: 128px;
  height: 32px;
}
.st-arc-btn {
  background-color: white;
  color: red;
  margin-left: 6px;
  display: block;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.07), 0px 0.5px 2px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  border: none !important;
}
.st-arc-btn,
.st-edit-btn,
.st-cancel-btn,
.st-save-btn {
  font-size: 12px;
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
  height: 28px;
}
.st-arc-btn:hover {
  background-color: red;
  color: white;
}
.st-cancel-btn:hover {
  background-color: #f4f4f4;
}
.st-edit-btn {
  background-color: white;
  color: var(--button-background-color);
  display: flex;
  align-items: center;
}
.st-edit-btn svg {
  margin-right: 4px;
}
.st-edit-btn:hover,
.st-save-btn:hover {
  color: white;
  background-color: var(--button-background-color);
}

.st-cancel-btn {
  background-color: white;
  color: red;
  display: none;
}

.st-save-btn {
  margin-left: 6px;
  color: blue;
  display: none;
}
.st-ed-inp {
  width: 150px;
  height: 32px;
  margin-right: 12px;
}
.st-display-inp {
  height: 32px;
}
.tk-note-display {
  height: auto;
  width: 418px;
  background: #fafafa;
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0px 40px;
  padding: 8px;
}
.tk-note-content {
  margin-top: 10px;
}
.tk-note-top {
  height: 15px;
  font-size: 12px;
  line-height: 15px;
  color: #718096;
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 0.0025em;
}
.tk-ed-ad-item-full {
  margin-bottom: 12px;
  border-radius: 4px;
  border: none;
  width: 418px;
  margin-left: 40px;
}
.tk-ed-added-item {
  height: 52px;
  background-color: #fafafa;
  border-bottom: 1px solid #cbd5e0;
  align-items: center;
  flex-direction: row;
}
.ad-item-text {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  border-right: 1px solid #cbd5e0;
}
.ad-item-text p {
  margin: 8px 0px 0px 12px;
}
.ad-item-inp {
  padding: 0px 12px 2px 12px;
}
.ad-item-btn {
  height: 100%;
  padding-top: 14px;
  padding-left: 4px;
  border-left: 1px solid #cbd5e0;
  cursor: pointer;
}
.ad-tk-item-btn {
  height: 100%;
  padding-top: 5px;
  padding-left: 8px;
  cursor: pointer;
}
.rel-item-inp {
  margin-top: 3px;
  position: relative;
}
.rel-item-inp .form-control,
.rel-item-inp input {
  border: 1px solid lightgray;
  border-radius: 4px;
  width: 252px;
}
#nitemthree,
#nitemfour,
#nitemfive,
#nitemsix,
#nitemseven,
#nitemeight {
  display: none;
}
/* .ad-item-btn{
  margin-left: 15px;
} */
.tk-ed-added-item .btn-primary {
  border: none;
  box-shadow: none;
  color: red;
  background-color: #fafafa;
  border: none;
  margin-top: 3 px;
}
.ad-item-inp {
  background-color: #fafafa;
  margin-top: 3px;
}
.display-ad-nt,
.display-edit-nt {
  display: none;
  margin-top: 10px;
}
.tk-new-note {
  height: 60px;
  width: 400px;
  border: none;
}
.add-tk-category .modal-dialog {
  box-shadow: none !important;
  border: none !important;
}
.ed-ct-arc-btn.btn-primary {
  background-color: white;
  color: #ef5f5f;
  width: 91px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.07), 0px 0.5px 2px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  border: none !important;
}
.ed-ct-arc-btn.btn-primary:hover {
  box-shadow: none !important;
  color: white;
  background-color: #ef5f5f;
}
.ed-ct-arc-btn.btn-primary:focus {
  box-shadow: none !important;
  background-color: white;
  color: #ef5f5f;
}
#edct-frst-cell {
  width: 230px;
}
#tk-tb-head {
  padding-left: 50px;
}
#edct-scnd-cell {
  width: 105px;
}
#edct-thrd-cell {
  width: 98px;
  margin-right: 7px;
}
#edct-cell-1 {
  margin: 6px;
}
#ed-st-tb-cell1 {
  padding-left: 50px;
}
#ed-st-tb-cell2 {
  padding-left: 75px;
}
#ed-display-tb-cell2 {
  padding-left: 33px;
}
/* View Tasks */
.vw-tk-table .MuiTableCell-sizeSmall {
  padding-right: 0px;
}
.vw-tk-table {
  border: 1px solid #cbd5e0 !important;
  margin-top: 10px;
  border-collapse: collapse;
  border-radius: 4px;
}
.vw-tk-table .MuiTableContainer-root {
  margin-top: 0px !important;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.vw-tk-table .MuiTableRow-root:last-child {
  border-bottom: none !important;
}
#vw-tk-fst-row {
  height: 56px;
  display: flex;
  align-items: center;
}
#vw-tk-fst-cell {
  display: flex;
  align-items: center;
}
.vw-tk-ck {
  margin-right: 12px;
  height: 16px;
  width: 16px;
}
.vw-tk-text {
  width: 130px;
}
.tk-cd-tabs {
  display: none;
}
.tk-success-msg {
  background-color: #e7f9f1;
  padding: 12px;
  border-radius: 4px;
  position: absolute;
  left: 45%;
  align-items: center;
  margin: 0;
}
.tk-msg-icon {
  margin: 0px 6px 4px 0px;
  color: #0a6038;
}
.vw-tk-back {
  color: var(--button-background-color);
  margin-left: 20px;
}
.ed-temp-block {
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
  border-radius: 8px;
  display: none;
  padding-bottom: 40px;
}
.ed-temp-top {
  border-bottom: 1px solid #cbd5e0;
  padding: 28px 40px;
}
.temp-tbl-btn {
  padding: 20px 40px;
}
.temp-back-btn .bck-btn {
  margin-right: 28px;
  border: none;
  height: 32px;
}
.ed-temp-title {
  font-size: 28px;
  color: #2e2c34;
}
.ed-tem-flx {
  display: flex;
  align-items: center;
}
.cr-new-temp.btn-primary {
  height: 32px;
}
.shw-arch.btn-primary {
  height: 32px;
  margin-left: 28px;
}
.cr-tem-date {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 42px;
}
.crt-tem-lbl {
  margin: 20px 55px 0px 40px;
}
.rel-item-list1 {
  display: none;
  position: absolute;
  overflow: auto;
  top: 100%;
  left: 0;
  z-index: 2;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
}
.pro-roles {
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
}
.pdfgen .modal-dialog {
  height: 396px;
}

.results .result:hover {
  background-color: #f9fafb !important;
}
.results .result:hover > .content {
  background-color: #f9fafb !important;
}
.pres-types {
  display: flex;
  align-items: center;
}
.phar-modal-btn {
  display: flex;
  align-items: center;
}
.phar-modal-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fafafa;
  padding: 16px 20px;
}
.add-fav-phar .modal-content {
  width: 700px !important;
}
.mid-top {
  display: flex;
  align-items: center;
}

.mid-top .form-check {
  margin-bottom: 8px !important;
  margin-left: 0px;
}
.phar-mid-one {
  margin-bottom: 15px;
}
.phar-middle-item {
  padding: 0px 20px;
}
.phar-modal-middle {
  margin-top: 18px;
}
.errormessage {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

/* ----------------------------super bill--------------------------------- */
.modal_top_0 .modal-dialog {
  top: 0 !important;
}
.modal_top_0 .modal-header .close {
  float: left;
  background: none !important;
  background-color: white !important;
  margin-left: 4px;
  margin-top: 14px;
  font-size: 40px;
}
.modal_top_0 .modal-header .close span {
  font-size: 40px;
  font-weight: 300;
}

.Invoice_table_Row .Invoice_table_ {
  margin-top: 20px !important;
}
.super_bill_main .modal-dialog {
  top: 0 !important;
}
.super_bill_body {
  background-color: #f1f5f8;
  margin: 30px auto;
}
.super_bill_body .modal-body {
  background-color: #ffffff;
  margin: 0px auto;
  border-radius: 10px;
  padding-bottom: 108px;
}
.card_container {
  display: flex;
  width: 100%;
}
.insurance_card,
.provider_card,
.patient_card {
  width: 47.5%;
}
.provider_sign,
.patient_sign {
  display: flex;
  margin-top: 25px;
  font-size: 14px;
  color: #000000;
  line-height: 17px;
  font-weight: 500;
}
.bill_info {
  font-size: 14px;
  color: #000000;
  line-height: 17px;
  font-weight: 500;
}
.list_group_itme_total {
  width: 100%;
  margin-top: 20px;
}
.list_group_itme_total .list-group {
  float: right;
}
.list_group_itme_total .list-group .list-group-item {
  border: none;
}
.sbill_pat_title {
  font-weight: 500;
  line-height: 33px;
  font-size: 28px;
  margin-top: 10px;
  color: #2e2c34;
  font-family: var(--font-work-sans);
}
.sbill_pat_date {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #2e2c34;
  margin-top: 24px;
  font-family: var(--font-work-sans);
  padding: 20px 0px 0px 0px;
}
.bill_pat_date {
  color: var(--button-background-color) !important;
  padding-left: 16px !important;
  font-family: var(--font-work-sans);
}
.card_container {
  margin-top: 20px;
}

.patient_card .card-header,
.provider_card .card-header {
  background-color: #ffffff;
  color: #000;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  height: 60px;
  padding-top: 19px;
}
.card {
  border: 1px solid #ebeaed;
  border-radius: 6px;
  overflow-x: hidden;
}
.space_bet {
  width: 2%;
}
.suber_bill_table {
  margin-top: 20px;
}
.card_body_flex {
  display: flex;
}
.card_title_text {
  color: #000;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  width: 43%;
  margin-bottom: 20px;
}
.w_42_ {
  width: 42% !important;
}
.card_value_text {
  font-size: 13px;
  color: #000;
  line-height: 20px;
  font-weight: 500;
  width: 145px;
}
.group_title_text {
  font-size: 16px;
  color: #2d3748;
  line-height: 17px;
  font-weight: 500;
}
.amouts_val {
  color: #2d3748 !important;
  float: right;
  font-size: 14px;
  font-weight: 500;
}
.footer_info {
  margin: 0px;
  padding: 34px 20px 20px 51px;
}
.input_val_ {
  padding-left: 5px !important;
  opacity: 0.5;
  padding-right: 10px;
}
.m-l-13 {
  margin-left: 13px;
}
.super_bill_main .modal-header .close {
  background-color: #f1f5f8 !important;
  margin-right: 5%;
  font-size: 39px !important;
  font-weight: 300 !important;
  margin-top: -4px;
}
.superbill_print_icon {
  width: 100%;
}
.print_sbill {
  font-size: 25px;
  color: #718096;
  cursor: pointer;
  float: right;
}
.b-r-block {
  border-right: 1px solid #cbd5e0 !important;
  border-top: 1px solid #cbd5e0 !important;
}
.b-l-top {
  border-top: 1px solid #cbd5e0 !important;
}
.pro-mod-top {
  background-color: #fafafa;
  padding: 16px 24px 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  background-color: #fafafa;
}
.pro-mod-tab-head {
  padding: 12px 0px 12px 20px;
  color: #718096;
  display: flex;
  align-items: center;
  border-top: 1px solid #cbd5e0;
}
.pro-mod-row {
  display: flex;
  align-items: center;
  padding: 12px 0px 12px 20px;
}
.pro-mod-table {
  margin-top: 12px;
}
.pro-bdr-bot {
  border-bottom: 1px solid #cbd5e0;
}
.dia-mod-top {
  padding: 16px 20px;
  background-color: #fafafa;
  display: flex;
  align-items: center;
}
.pro-mod-table {
  margin-top: 12px;
}
.pro-mod-row {
  border-bottom: 1px solid #cbd5e0;
  display: flex;
  align-items: center;
}
.diagnosis-modal .modal-dialog {
  max-width: 650px;
}
.diagnosis-modal .modal-content {
  width: 650px;
}
.search_width .input {
  width: 100%;
}
.m-b-30 {
  margin-bottom: 30px;
}
/* ----------------------PateientStatement Start--------------------------------- */

.patient_statement_body_div .modal-body {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 7px 7px 5px #f4f4f4;
}

.patient_staement_h {
  display: flex;
  width: 100%;
  padding: 0px 20px;
  margin-top: 50px;
}

.patient_staement_h .from-addr {
  width: 40%;
}

.pro-name {
  margin-left: 0px !important;
}
.pro-street {
  margin-top: 7px;
}
.pro-addr {
  margin-top: 3px;
}

.right-tbl {
  width: 60%;
}
.tr_border_block {
  border: 1px solid #cbd5e0 !important;
}
.pt_st_row_th th,
.pt_st_row_tr td {
  border: 1px solid #cbd5e0 !important;
  border-radius: 0px !important;
}
.to-addr,
.bill-note-pro {
  width: 50%;
}
.patient_staement_to {
  display: flex;
  width: 100%;
  padding: 0px 20px;
  margin-top: 25px;
}
.pat_detail_patst {
  display: flex;
  width: 100%;
  margin-top: 20px;
}
.bill_pat_name1,
.bill_pat_name2 {
  border-top: 2px solid #cbd5e0;
  border-bottom: 2px solid #cbd5e0;
  border-left: 2px solid #cbd5e0;
  height: 40px;
  padding-top: 6px;
  width: 33.3%;
}
.bill_pat_name3 {
  border: 2px solid #cbd5e0;
  height: 40px;
  padding-top: 6px;
  width: 33.3%;
}
.bottom-tbl {
  width: 100% !important;
  margin-top: 20px;
}
/* ----------------------PateientStatement End--------------------------------- */

.switch-app {
  display: flex;
  margin-bottom: 10px;
  margin-left: -9px;
}
.lab-res-box {
  border: 1px solid #2d3748;
  width: 20%;
  margin-left: 40%;
  margin-bottom: 20px;
  padding: 12px;
}
.lab-res-lab {
  width: 130px;
  text-align: left;
  margin: 10px 0px;
}
.lab-res-res {
  margin: 10px 0px;
  text-align: left;
}
.lab-res-row {
  display: flex;
  align-items: center;
}
.frm-itm .ant-form-item-control {
  width: 100px;
}
.inv-ins-close {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #f1f2f5;
  float: left;
  margin: 16px 0px 0px 20px;
}
.modifiers-for-hcpcs-ndc .form-control {
  padding: 0.375rem 0.65rem !important;
}
/* jeybalaji */
.pass-expire-text {
  font-weight: 500;
  font-family: var(--font-work-sans);
  font-style: normal;
  font-size: 16px;
  line-height: 14px;
  color: #4b4b4b;
}
.pass-expire-box {
  position: relative;
  border-radius: 10px;
  padding: 8px 8px 0px;
}
.pass-exp-desc {
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #132047;
}
.pass-exp-btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.pass-exp-form {
  margin-top: 24px;
}
.password-expired-modal .modal-dialog {
  margin-top: 20%;
  margin-right: 33.5%;
  border-radius: 25px 25px 0px 0px !important;
  border: none !important;
  margin-bottom: 0px !important;
}
.password-expired-modal .modal-content {
  border-radius: 25px 25px 0px 0px !important;
  margin-bottom: 0px;
}
.pass-exp-rotated-rect {
  position: absolute;
  top: -15.5px;
  left: -21px;
}
.pat-flag-add-new-sty .modal-dialog {
  height: 245px !important;
}
/* jeybalaji */
/* ------START User Guide CSS ------------ */

.user-gui-title {
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  display: block;
  align-items: center;
  margin-left: 20px;
  margin-top: 6px;
}
#user_guide_page .modal-header {
  border-bottom: none !important;
}
#user_guide_page .modal-header .close {
  margin-top: 10px !important;
  margin-right: 20px !important;
}

.user-gui-title-small {
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #718096;
  width: 350px;
  margin-left: 20px;
  margin-top: -8px !important;
}

.user-guide-list-1 {
  display: flex;
  margin-top: 32px !important;
  margin-left: 20px !important;
}
.user-guide-list-2 {
  display: flex;
  margin-top: 20px !important;
  margin-left: 20px !important;
}
.user-guide-list-7 {
  display: flex;
  margin-top: 20px !important;
  margin-left: 20px !important;
  margin-bottom: 6px !important;
}
.for-user-guide-list-data {
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #718096;
  padding-right: 12px !important;
  /* margin-top: 10px !important; */
}
.for-user-guide-list-data-1 {
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #3f8cff;
  cursor: pointer;
}
.for-user-guide-list-data-2 {
  margin-top: 4px !important;
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #718096;
}
.user-gui-title-footer {
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #718096;
  margin-left: 47px;
  margin-right: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.user-guide-popup-box .modal-content {
  width: 540px !important;
}

.user-guide-popup-box .modal-dialog {
  /* margin-top: 3%;
  margin-right: 33.5%; */

  float: none;
  right: unset !important;
  /* display: flex; */
  position: relative;
  margin: 0 auto;
  top: 25%;
}

/* ------END User Guide CSS ------------ */
/* get started client START */

.get-st-client-head {
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #000000;
  margin-left: -14px !important;
  margin-top: -5px !important;
  margin-bottom: 6px !important;
  z-index: 1000;
}
.get-st-client-content {
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.0025em;
  color: #464646;
  margin-top: 6px !important;
  height: 39px !important;
  width: 290px !important;
  margin-left: -14px !important;
}
.get-st-client-content-3 {
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.0025em;
  color: #464646;
  margin-top: 6px !important;
  height: 39px !important;
  width: 290px !important;
  /* margin-left: -14px !important; */
}
.get-st-client-content-2 {
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.0025em;
  color: #464646;
  margin-top: 12px !important;
  height: 39px !important;
  width: 290px !important;
  /* margin-left: -14px !important; */
}
.for-get-started-popup-for-res {
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 14px !important;
  padding-bottom: 12px !important;
}
.for-get-started-popup-for-res-2 {
  padding-left: 6px !important;
  padding-right: 20px !important;
  padding-top: 10px !important;
  padding-bottom: 14px !important;
}

.get-st-next-btn {
  width: 110px;
  height: 34px;
  left: 870px;
  top: 477px;
  background: var(--button-background-color);
  border-radius: 6px;
  font-weight: 500;
  /* margin-left: 45px !important; */
}
.get-st-box-with-rad-but .ant-radio-group {
  width: 200px !important;
}
.get-st-box-with-rad-but-2 .ant-radio-group {
  width: 200px !important;
}
.get-st-box-with-rad-but {
  display: flex;
  padding-top: 20px !important;
  /* padding-bottom: 4px !important; */
  margin-bottom: -5px !important;
  margin-right: -14px !important;
  align-items: center;
  margin-left: -14px !important;
}
.get-st-box-with-rad-but-2 {
  display: flex;
  padding-top: 20px !important;
  /* padding-bottom: 4px !important; */
  margin-bottom: -5px !important;
  margin-right: -14px !important;
  align-items: center;
  /* margin-left: -14px !important; */
}

.get-st-box-with-rad-but .ant-radio-checked .ant-radio-inner {
  /* border-color: red !important ; */
  border: none !important;
  /* margin-top: -12px !important; */
}
.get-st-box-with-rad-but .ant-radio-checked .ant-radio-inner::after {
  top: 0px !important;
  left: 0px !important;
}
.get-st-box-with-rad-but .ant-radio .ant-radio-inner {
  width: 8px !important;
  height: 8px !important;
  border: none !important;
  background-color: #c4c4c4;
}
.get-st-box-with-rad-but-2 .ant-radio-checked .ant-radio-inner {
  /* border-color: red !important ; */
  border: none !important;
  /* margin-top: -12px !important; */
}
.get-st-box-with-rad-but-2 .ant-radio-checked .ant-radio-inner::after {
  top: 0px !important;
  left: 0px !important;
}
.get-st-box-with-rad-but-2 .ant-radio .ant-radio-inner {
  width: 8px !important;
  height: 8px !important;
  border: none !important;
  background-color: #c4c4c4;
}
.get-started-client-pop .modal-dialog {
  height: 208px !important;
  width: 415px !important;
  margin-top: 260px !important;
  margin-right: 50%;
}

.for-get-st-arrow-1 {
  transform: rotate(45deg);
  margin-top: -28px !important;
  background: white;
  height: 30px !important;
  width: 30px !important;
  margin-left: 29px !important;
  border-radius: 4px !important;
}
/* get started client END*/
/* get started vitals START */
.get-started-vitals-pop .modal-dialog {
  margin-top: 250px !important;
  margin-right: 29%;
  height: 208px !important;
  width: 415px !important;
}
/* get started vitals END */
/* jeyabalaji */

/* updated by saravanan starts */

/* About welcome popup */
/* @import url("https://fonts.googleapis.com/css2?family=Inter&family=Work+Sans:wght@500&display=swap"); */

.welcome-scrn .modal-dialog {
  position: absolute;
  top: -30px;
  left: -1%;
  width: 100%;
  border: none !important;
  box-shadow: none !important;
  padding-bottom: 40px;
}

.about-modal {
  font-family: var(--font-work-sans);
  font-style: normal;
  text-align: center !important;
  max-width: 100%;
  max-height: 100%;
}

#about-modal-heading {
  color: #6599ff;
  max-width: 200%;
  width: 100%;
  text-align: center !important;
  font-weight: 700;
  font-size: 40px;
  line-height: 46px;
  letter-spacing: -0.03em;
  padding-left: 9%;
  margin-top: 0px;
  margin-bottom: 0px;
}
.about-video-buttons {
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: 158px 158px; */
  /* background:url(../../assets/images/login.png) no-repeat left top, linear-gradient(rgba(15, 15, 15, 1),rgba(255, 255, 255, 0.8) ); ; */
  /* background-image:linear-gradient(0deg, #000000 0%, rgba(255, 255, 255, 0) 108.44%,url(../../assets/images/login.png)); */
  width: 158px;
  height: 130px;
}
.about-video-picture1 {
  background-image: url(../../assets/images/Scheduling.png);
}
.about-video-picture2 {
  background-image: url(../../assets/images/Reporting.png);
}
.about-video-picture3 {
  background-image: url(../../assets/images/MakingPayment.png);
}
.about-video-picture4 {
  background-image: url(../../assets/images/PatientDemographics.png);
}

.about-small-heading {
  font-weight: 500;
  color: #5b5b5b;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 2px !important;
}
.about-paragraph {
  color: #5b5b5b;
  font-weight: 400;
  margin-top: 8px;
  font-size: 14px;
}
.about-second-heading {
  color: #5b5b5b;
  font-weight: 600;
  font-size: 21px;
  padding-top: 32px;
}
.about-second-heading:after {
  content: "";
  display: block;
  margin: 4px auto 16px;
  width: 30%;
  padding-top: 5px;
  opacity: 0.5;
}
.about-started-button {
  margin-left: 37%;
  margin-right: 10%;
  color: white;
  background-color: #6599ff;
  font-size: 16px;
  max-width: 250px;
  width: 214px;
  height: 43px;
  margin-bottom: 17px;
}
.about-modal .modal-dialog {
  max-width: 800px;
  width: 800px;
  margin-left: auto;
  float: none;
  margin-right: auto;
  margin-top: 5%;
  margin-bottom: auto;
}
.about-modal .modal-title {
  margin-top: none !important;
  max-width: 100%;
}
.about-modal .modal-content {
  padding-left: 1%;
  padding-right: 1%;
}
.about-modal-video .modal-dialog {
  max-width: 80%;
  max-height: 30%;
  height: 50vh;
  margin-left: auto;
  float: none;
  margin-right: auto;
}

.about-modal .modal-header {
  border-bottom: none !important;
  padding-top: 5px !important;
  padding-bottom: 10px !important;
  margin-top: 0px !important;
  max-height: 100px;
  height: 70px;
}
.about-modal .modal-footer {
  border-top: none !important;
}
.about-modal-video .close {
  margin-top: 6px;
}
/* .about-modal .about-row {
  margin-left: 33px;
} */
.wel-vert-line {
  position: absolute;
  top: 0px;
  left: 396px;
  width: 1px;
  height: 185px;
  background-color: lightgrey;
}
/* About Welcome popup ends here */

/* About Feedback popup starts here */
.modal-feedback {
  font-family: var(--font-work-sans);
  font-style: normal;
  max-width: 100%;
  max-height: 100%;
}
.modal-feedback .modal-dialog {
  max-width: 1000px;
  width: 360px;
  max-height: fit-content;
  margin-left: auto;
  float: none;
  margin-right: auto;
  margin-top: 24%;
  margin-bottom: auto;
  border-top-left-radius: 25px !important ;
  border-top-right-radius: 25px !important ;
  border-bottom-left-radius: 0px !important ;
  border-bottom-right-radius: 0px !important ;
}
.modal-feedback .modal-content {
  border-top-left-radius: 25px !important ;
  border-top-right-radius: 25px !important ;
  border-bottom-left-radius: 0px !important ;
  border-bottom-right-radius: 0px !important ;
  padding-left: 12px !important;
}
.modal-feedback-heading {
  padding-top: 21px;
}
.modal-feedback-heading1 {
  font-family: var(--font-work-sans);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
.modal-feedback-heading2 {
  font-family: var(--font-work-sans);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--button-background-color);
  padding-left: 1px;
}
.modal-feedback .modal-header {
  border-bottom: none !important;
  padding-bottom: 2px !important;
}
.modal-feedback-header2 {
  padding-top: 0px !important;
}
.modal-feedback .modal-header .modal-feedback-heading2 {
  border-bottom: none !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.modal-feedback .modal-body {
  padding-top: 5px !important;
}
.modal-feedback-para {
  font-family: var(--font-work-sans);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #718096;
  margin-bottom: 0px !important;
  margin-top: 1px !important;
}
.modal-feedback-ques {
  font-family: var(--font-work-sans);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #2e2c34;
  max-width: 400px;
  width: 263px;
  margin-top: 35px;
  margin-bottom: 10px !important;
  padding-left: -2px !important;
}
.modal-feedback-button {
  width: 44px;
  height: 44px;
  border-radius: 4px !important;
  background-color: #f4f4f4;
  color: black;
  border: unset;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  margin-right: 16px;
}
.modal-feedback-buttongroup {
  padding-left: 1px;
}
.modal-feedback .modal-footer {
  border-top: none !important;
  margin-top: 12px;
  padding-left: 17px !important;
}
.modal-feedback-footer-button {
  width: 74px;
  height: 33px;
  border-radius: 5px;
  color: white;
  background-color: var(--button-background-color);
  margin-bottom: 30px;
  margin-right: 17px;
  padding-left: px !important;
}
.modal-feedback-cancel-button {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  color: black;
}
.modal-feedback-cancel-button:hover {
  border: 1px solid #d9d9d9;
  background-color: #fafafa !important;
  color: Black;
}

/* About Feedback popup ends here */

/* About Expire popup starts here */

.expire-modal {
  font-family: var(--font-work-sans);
  font-style: normal;
  max-width: 100%;
  max-height: 100%;
}

.expire-modal .modal-dialog {
  max-width: 600px;
  width: 544px;
  height: fit-content;
  margin-left: auto;
  border: none !important;
  float: none;
  margin-right: auto;
  margin-top: 15%;
  margin-bottom: auto;
}

.expire-modal-heading {
  font-weight: 600;
  font-size: 16px;
  margin-left: 25px;
  line-height: 14px;
  letter-spacing: 0.0015em;
  color: #4b4b4b;
  padding-top: 35px;
}
.expire-modal-para {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0015em;
  margin-left: 25px;
  color: #718096;
  margin-right: 47px;
}
.expire-modal-button {
  width: 115px;
  height: 37px;
  background: var(--button-background-color);
  margin-left: 20px;
  margin-bottom: 15px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.0015em;
  color: #ffffff;
}
.exp-mod-cancel {
  width: 115px;
  height: 37px;
  margin-left: 25px;
  border-radius: 6px;
  margin-bottom: 15px;
  border: 1px solid #cbd5e0;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.0015em;
}
.expire-modal .modal-header {
  border-bottom: none !important;
  /* padding-top: 5px !important;
  padding-bottom: 10px !important;
  margin-top: 0px !important;
  max-height: 100px;
  height:70px; */
}
.expire-modal .modal-footer {
  border-top: none !important;
}
.pass-alert-rect {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
}
/* About Expire popup ends here */

/* updated by saravanan ends */

/*----------- Vanitha---------------- */
.app-popup .modal-header .close {
  margin-left: auto !important;
}
/* -------------Vanitha------------------- */
/* updated by saravanan start */
.del-head-warning {
  margin-left: 20px;
  padding: 12px 45px 12px 16px;
  background-color: #fcebe8;
  color: #bb2f26;
  border: 1px solid #f18874;
  box-sizing: border-box;
  border-radius: 4px;
}
.del-scnd-text {
  font-family: var(--font-work-sans);
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 16px;
  color: #2d3748;
}
.sub-exp-icon-txt {
  margin: 26px 0px;
  text-align: left;
  font-size: 20px;
  color: #ef5f5f;
}

.sub-box-1,
.sub-box-2 {
  text-align: left;
  font-family: var(--font-work-sans);
}
.sub-box-2 {
  font-size: 12px;
  margin-top: 8px;
  color: #f1f5f8;
}
.sub-exp-cancel-btn.btn.btn-outline-primary {
  height: 34px !important;
  margin-top: 20px;
}
.top-nav-blocker {
  z-index: 1001;
  height: 50px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #f1f5f8;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 14px;
}
.sub-top-nav {
  display: flex;
  justify-content: space-between;
  padding: 0px 40px 0px 20px;
  align-items: center;
}
.del-acc-main-block {
  padding: 0px 20px 40px;
}
.del-acc-reason {
  font-family: var(--font-work-sans);
  text-align: left;
  font-size: 14px;
  margin: 24px 0px 16px;
  color: #242433;
}
.single-check-box {
  margin-bottom: 16px;
}
.single-check-box .ant-checkbox-wrapper {
  margin-top: 0px !important;
}
.del-acc-other-input.form-control {
  width: 56%;
  margin-top: 4px;
  margin-left: 24px;
}
.del-textarea-box {
  margin-top: 16px;
}
.del-inside-box {
  width: 74%;
  text-align: right;
}
.del-textarea-txt {
  font-family: var(--font-work-sans);
  font-weight: 500;
  text-align: left;
  width: 100%;
  margin-bottom: 16px;
  font-size: 16px;
  color: #2d3748;
}
.del-acc-textarea {
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  height: 179px;
}
.ack-third-layer {
  margin-left: 20px;
}
.ack-scnd-layer {
  margin: 20px;
}
.ack-four-layer {
  margin: 20px 20px 24px;
}
.ack-title-head {
  font-family: var(--font-work-sans);
  font-weight: 500;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: #000000;
}
.ack-scnd-txt {
  font-family: var(--font-work-sans);
  font-weight: 500;
  font-size: 16px;
}
.ack-m-t-12 {
  margin-top: 12px;
}
.ack-w-400 {
  width: 400px;
}
.ack-w-460 {
  width: 460px;
}
.ack-head-span {
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}
.ack-w-788 {
  width: 788px;
}
.last-inp-four.form-control {
  width: 260px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}
.last-inp-four.form-control::placeholder {
  color: #cccccc !important;
  font-family: var(--font-work-sans) !important;
}
.ack-p-t-24 {
  padding-top: 24px;
}
.ack-full-box {
  padding-bottom: 100px;
}
.del-acc-other-input.form-control::placeholder {
  color: #cccccc !important;
  font-family: var(--font-work-sans);
  font-size: 14px;
}
.top-blocker-logo {
  width: 75px;
  height: 48px;
}
.sub-top-logout .dropdown-menu.show:not(.card_container) {
  margin-left: 92px !important;
  border: none;
}
.pos-abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sub-p-24 {
  padding: 24px;
  width: 80%;
}
.pos-rel-sub-ecp {
  position: relative;
  height: 800px;
}
.sub-main-box {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  justify-content: space-between;
  width: 100%;
  background: linear-gradient(105.62deg, #2347e7 -19.56%, #c624ff 132.54%);
}
.sub-box-1 {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.0015em;
  color: #ffffff;
}
.upg-req-one {
  margin: 16px 20px 15px;
}
.upg-req-margin {
  font-family: var(--font-work-sans) !important;
}
.upg-req-two {
  margin: 0px 20px 16px;
}
.upg-req-margin.modal-body {
  padding: 0 !important;
}
.req-1-line1 {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.0015em;
  color: #404040;
}
.req-1-line2 {
  font-size: 12px;
  margin: 8px 0px;
  line-height: 20px;
  letter-spacing: 0.0015em;
  color: #404040;
}
.req-1-line3 {
  height: 30px;
  background: rgba(44, 123, 229, 0.2);
  border-radius: 4px;
  color: var(--button-background-color);
  font-size: 12px;
  display: flex;
  padding-left: 8px;
  align-items: center;
}
.req-2-line1 {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.0015em;
  margin-bottom: 8px;
  color: #404040;
}
.req-2-line2 {
  font-size: 11px;
  letter-spacing: 0.0015em;
  color: #404040;
  width: 84%;
}
.upgrade-req-modal .modal-dialog {
  width: 550px !important;
}

/* updated by saravanan ends */
/* update by cheran start */
.but-act .btn-primary:not(:disabled):not(.disabled).active {
  color: white !important;
  background-color: var(--button-background-color) !important;
  /* box-shadow: none !important; */
}
/* .but-act .btn:hover {
  color: white !important;
} */
/* .but-act .btn {
  box-shadow: none !important;
} */
/* update by cheran end */
/* saravanan 28.3.22 7:30 start */
.slct_vlid_pat_add_task .rel-item-inp .ui.search.selection.dropdown input {
  border: 1px solid red !important;
}
.rel-item-inp .ui.search.selection.dropdown input {
  height: 34px;
  border: 1px solid #dbdbdb !important;
}
.rel-item-inp .ui.search.selection.dropdown {
  border: none;
  width: 240px;
}
/* saravanan 28.3.22 7:30 end */
/* Vanitha 15-04-22 Start  */

.alle_sea_alt .ui.icon.input > i.icon {
  top: -1px !important;
}

.alle_sea_alt .ui.input {
  font-size: 10px !important;
  height: 32px !important;
}
.load_more_alt {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.alle_sea_alt .ui.input > input {
  margin-right: 0px !important;
}
.search_ins_med .ui.icon.input > input {
  padding-right: 0px !important;
}

/*  Vanitha 15-04-22 End  */

.us-ad-rl-err {
  color: #dc3545 !important;
  margin: -10px 0px 10px 5px;
}
.ur-rl-rem-mrt {
  margin-top: 0px !important;
}
.about-modal-heading {
  color: #6599ff;
  max-width: 200%;
  width: 100%;
  text-align: center !important;
  font-weight: 700;
  font-size: 24px;
  line-height: 46px;
  letter-spacing: -0.03em;
  padding-left: 9%;
  margin-top: 0px;
  margin-bottom: 0px;
}
.continue-btn-billing {
  height: 32px;
  background-color: var(--button-background-color);
  font-weight: 500;
  margin-left: auto;
  margin-right: 13px;
}
/*-----------------Update by cheran 3-5-22 Start---------------*/
.drop-sel-adcon {
  width: 102px !important;
}
.drop-sel-adcon1 {
  width: 102px !important;
  margin-left: 30px !important;
}
.popup-adc {
  padding-top: 3px !important;
  margin-bottom: 8px !important;
}
.popup-adph {
  margin-left: 0px !important;
  padding-top: 6px !important;
}
.adc-btn {
  margin-top: 6px !important;
}
.adc-delicon {
  padding-top: 3px !important;
  padding-left: 41px !important;
}

/*-----------------Update by cheran 3-5-22 End---------------*/
/*-----------------Update by cheran 4-5-22 Start---------------*/
.srx-daw {
  margin-left: 23px;
}
.demo-con-city {
  width: 134px !important;
}
.adc-street-c {
  width: 346px !important;
}
.upd-btn-sig {
  color: white !important;
}
.em-check .ant-checkbox-inner {
  margin-right: 7px !important;
}
.em-check .ant-checkbox-wrapper {
  margin-right: 0px !important;
}
.sig-row {
  padding: 0px 0px 0px 16px;
}
/*-----------------Update by cheran 4-5-22 End---------------*/
/*-----------------Update by cheran 5-5-22 Start---------------*/
.mcf-sig {
  margin-right: -20px !important;
  margin-left: -20px !important;
  margin-bottom: -13px !important;
}
.mdl-padtpbtm {
  padding-top: 19px !important;
}
.mdl-padtpbtm1 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.mdl-padtpbtm2 {
  padding-bottom: 0px !important;
}
.adbp-row-adj {
  margin-top: 1px;
  margin-bottom: 2px;
}
.adbp-row-adj1 {
  margin-top: 1px;
  padding-top: 2px;
}
.adbp-row-adj2 {
  margin-top: 1px;
  padding-bottom: 2px;
  margin-bottom: 2px;
}
.adpy-row-adj {
  padding-top: 6px;
}
.adpy-row-adj1 {
  padding-top: 6px;
  padding-bottom: 6px;
}
.md-code-table {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 6px !important;
  padding-bottom: 1px !important;
}
.adc-mt-adjust {
  margin-top: 0px;
}
.dot-active1 {
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  position: relative;
  top: -1px;
  width: 8px;
  margin-right: 2px !important;
  margin-top: -2px;
}
/*-----------------Update by cheran 5-5-22 End---------------*/
.appointmentTimepick {
  width: 88px !important;
}
.appointmentDatepick {
  width: 100px !important;
}
.vitalsModal {
  clear: both;
  padding: 8px !important;
  padding-top: 0;
}
.vitalsButton {
  margin-right: 20px;
  margin-top: 4px;
  margin-bottom: -20px;
}
#settingFormCheck .settingFacilityCheck {
  margin-top: 0px !important;
}
.addSettingLabCheck {
  margin-left: 0px !important;
}
.modalProblemsTitle {
  margin-top: -3px !important;
}
.addProblemTextArea {
  padding: 0px;
}
.addProblemFormgrouop {
  margin-bottom: 16px !important;
}
.addProblemFormHr {
  margin-top: 0px;
  margin-bottom: 9px;
}
#add-prob #addProblemFooter {
  padding-bottom: 15px !important;
}
#add-prob .modal-header .close {
  font-size: 22px !important;
  font-weight: 600 !important;
}
.radio_group_add_pay .for_balance {
  min-width: 190px;
}
.radio_group_add_pay .for_inv_balance {
  min-width: 190px;
}
.radio_group_add_pay .for_other_balance {
  min-width: 122px;
  margin-top: 1px;
}

.radio_group_add_pay {
  padding-bottom: 16px;
  padding-top: 12px;
}

.calender-popup .new_appt_detail_footer {
  justify-content: space-between !important;
  padding-left: 16px !important;
}

.for-service-codes-bground .ui.icon.input > input {
  background-color: white !important;
}
.pro-rep-justify {
  align-items: center;
}
.tk-add-btn {
  margin-bottom: 3px;
  margin-right: 6px;
}
.tk-inp-grp {
  width: 160px;
  height: 34px;
  border: 1px solid #cbd5e0;
  margin: 16px 20px 16px 0px;
  background-color: #fff;
}
.tk-btn-grp {
  background-color: #fafafa;
  border: 1px solid #ebeaed;
  color: #718096;
  border: 1px solid #cbd5e0;
}
.tk-inp-pad-sml {
  padding: 5px 2px 5px 12px;
}
.tk-inp-marg-lrg {
  margin: 16px 20px 16px 0px;
}
.tk-hgt-only-34.btn-group,
.tk-hgt-only-34.btn.btn-primary,
#add-facility .ant-select-selector {
  height: 34px !important;
}
.tk-bg-1 {
  background-color: #fcfcfc;
}
.tk-frmc-wid.form-control,
.tk-frmc-wid .ant-select-selector {
  width: 200px !important;
}
.tk-angle-icon {
  margin: 0px 6px 3px -4px;
}
.tk-add-icon {
  margin: 1px 6px 0px -5px;
}
.tk-ed-icon {
  color: var(--button-background-color);
  cursor: pointer;
}
.tcd-angle-icon {
  margin: 0px 4px 2px 0px;
}
.tcd-stwat {
  width: 14px;
  height: 16px;
  color: #84818a;
  margin-top: 2px;
  margin-right: 4px;
}
.tcd-font {
  font-size: 12px;
  font-weight: 500;
}
.tcd-font-2 {
  font-size: 12px;
  font-weight: 500;
}
.ed-sta-ad-icon {
  width: 15px;
  height: 15px;
  padding: 0;
  margin: 0px 3px 3px 0px;
}
.eds-sv-icon {
  margin: 0px 3px 1px 0px;
}
.eds-fscan-icon {
  margin: 0px 3px 3px 0px;
}
.eds-del-btn-icon {
  margin: 0px 5px 4px 0px;
}
.eds-mod-bdy.modal-body {
  padding: 0;
  width: 650px;
  max-height: 450px;
  overflow: auto;
}

.ad-tk-temp {
  margin: 28px 0px 0px 20px;
}
.ad-tk-title {
  font-weight: 600;
  font-size: 18px;
}
.ad-tk-not {
  margin: 35px 0px 0px 40px;
}
.ad-tk-assoc {
  margin: 18px 0px 17px 20px;
}
.ad-tk-pat-inp {
  margin: 10px 20px 10px 0px;
}
.ad-tk-iccl-icon {
  font-size: 14px;
}
.ad-tk-nt-sty {
  height: 12px;
  margin-bottom: 3px;
  margin-right: 3px;
  cursor: pointer;
}
.add_tasks .modal-dialog {
  width: 460px;
}
.edit_tasks .modal-dialog,
.create_template .modal-dialog {
  width: 700px !important;
}
.task-edit-status .modal-dialog {
  width: 654px !important;
}
.add-tk-category .modal-dialog {
  width: 500px !important;
}
.ad-tk-nt-st {
  margin: 35px 0px 0px 20px;
}
.tme-tk-sty {
  font-weight: 600;
  font-size: 18px;
}
.tem-ed-notl {
  margin: 16px 113px 8px 44px;
}
.tem-ed-notinp {
  margin: 19px 40px 8px 0px;
}
.ad-tk-del-icon {
  color: red;
  height: 15px;
  width: 15px;
}
/*Ahamed_Changes_06-05-22_Start*/
.add-appt-prof-pop button.close {
  margin-top: -3px;
}
.add-appt-prof-pop .modal-body {
  padding-top: 16px !important;
}
.add-appt-prof-pop label.col-form-label {
  padding-left: 19px !important;
}
.add-appt-prof-pop .mb-3 {
  margin-bottom: 10px !important;
}
.add-appt-prof-pop input[type="color"] {
  height: 34px !important;
}
#add-allergy.add-appt-prof-pop .modal-footer {
  border-top: 1px solid #cbd5e0 !important;
  padding: 16px 16px 17px 16px !important;
}
.add-appt-prof-pop .label-for-minutes {
  margin-top: -45px;
  margin-left: 242px;
  margin-right: 0px !important;
}
.client-popup-for-profiles .modal-dialog {
  max-height: 467px;
}
#add-allergy .modal-header {
  padding: 25px 20px 42px 20px !important;
}
.client-popup-for-profiles .modal-dialog {
  width: 432px;
}
#client-create .disable-text {
  font-size: 12px !important;
}
#client-create .form-row {
  padding-top: 8px;
}
#client-create .close-btn {
  margin-left: 0;
}
/* Vanitha 08-05-22 */
#add-allergy > form > div.modal-header {
  padding-bottom: 18px !important;
  padding-top: 19px !important;
}

.alle_mod_Body_alt {
  margin-top: -4px !important;
}

.alle_bot_bor_foot_alt_ {
  margin-top: 2px !important;
  margin-bottom: 13px !important;
}

.alle_tab_whole_bot_pad {
  margin-bottom: -1px !important;
}

#add-med .modal-header .close {
  padding: 0px 4px 5px 4px !important;
  font-size: 1.5rem !important;
}

#add-med .modal-header {
  margin-top: 6px !important;
  padding-bottom: 17px !important;
}

.med_mod_body_alt {
  margin-top: -9px !important;
}

.med_mod_body_alt1 {
  margin-top: -5px !important;
}

.med_mod_body_alt2 {
  margin-top: -4px !important;
}

.med_mod_body_alt3 {
  margin-top: -6px !important;
}

.add-fam .modal-header .close {
  padding: 0px 4px 7px 4px !important;
  font-size: 1.5rem !important;
}

.add-fam .modal-header {
  padding-top: 20px !important;
  padding-bottom: 17px !important;
}

.add_observation1 .modal-header .close {
  font-size: 1.5rem !important;
  padding: 0px 3px 5px 3px !important;
}

.add_observation1 .modal-header {
  padding-top: 20px !important;
  padding-bottom: 17px !important;
}

.addobs_mod_foot_alt {
  margin-top: -12px !important;
  padding-top: 16px !important;
  padding-bottom: 17px !important;
}

/* Vanitha 08-05-22 */

/* Vanitha 09-05-22 */

.mod_foot_alt {
  margin-top: 17px !important;
  margin-bottom: 4px !important;
}

.mod_foot_alt button {
  margin-right: 12px !important;
}

.add_patflag_headr_alt {
  padding-top: 20px !important;
  padding-bottom: 16px !important;
}

.add_patflag_body_name_alt {
  margin-top: -2px !important;
}

.add_patflag_body_clr_alt {
  margin-top: 1px !important;
  margin-bottom: -12px !important;
}

.add_patflag_footr_alt {
  padding-top: 17px !important;
  padding-bottom: 16px !important;
}

.pat_flag_modbody_alt {
  margin-left: 0px !important;
}

.pat_footr_pad_alt {
  padding-top: 17px !important;
  padding-bottom: 16px !important;
  padding-left: 16px !important;
}

.pat_footr_padlef_alt {
  margin-left: 1px !important;
}

.pat_flag_popsave_btn_alt {
  padding-top: 1px !important;
}

.patflag_pop_namealt .popup-for-patientcontol {
  margin-left: 12px !important;
}

.add_aller_pop_body {
  margin-top: -9px !important;
}

.add_alle_foot_alt {
  margin-left: -4px !important;
}

/* Vanitha 09-05-22 */
/*------------Update by cheran 9-5-22 Start--------------*/
.bp-cel-top {
  border-top: none !important;
}
.md-ssb {
  padding-top: 3px !important;
}
.tbc-bdr1 {
  border-radius: 6px !important;
}
.tbc-bdr {
  border-radius: 6px !important;
}
.tr-hght {
  height: 50px !important;
}
.tbfc {
  border-top: none !important;
}
.bp-mgbtm-1px {
  margin-bottom: 1px !important;
}

.for-mdl-el-mg {
  padding-left: 20px !important;
}
.for-row-pl {
  margin-right: -110px !important;
}
.for-row-width {
  width: max-content !important;
  margin-top: -3px !important;
}
.ht-mdl-bp {
  height: 338px !important;
  overflow-x: hidden !important;
}
.fr-p-top1 {
  margin-top: 2px !important;
}
.fr-p-top {
  margin-top: 6px !important;
}
body > div.fade.client-popup-for-billing-profiles.modal.show > div {
  height: max-content !important;
}
/*------------Update by cheran 9-5-22 End--------------*/
.addProblemMarginTop {
  margin-top: 2px;
}
.addProblemMargTop {
  margin-top: -19px;
}

/* Vanitha 13-05-22 */

.prov_avai_popup_repe_allev {
  margin-left: -12px !important;
}

/* Vanitha 13-05-22 */
.tk-md-bd-overflow.modal-body {
  height: 500px !important;
  overflow: auto;
  padding: 0;
}
.temp-md-control .form-control,
.temp-md-control .ant-select-selector {
  width: 275px !important;
}
.eds-mdfoot.modal-footer {
  padding: 20px !important;
}
.eds-mdfoot .form-control {
  width: 180px;
}
.eds-mdfoot.modal-footer input {
  margin: 0px !important;
}
.create_template .modal-dialog {
  width: 447px !important;
}
.temp-lbl-size label {
  font-size: 12px;
}
.temp-lbl-size .form-control {
  width: 50px;
}
.view-task-text-colour.MuiTableBody-root .MuiTableRow-root td:nth-child(2) {
  color: #2e2e2e !important;
}
.view-task-text-colour.MuiTableBody-root .MuiTableRow-root td:nth-child(4) {
  color: var(--button-background-color) !important;
}
.view-task-text-colour.MuiTableBody-root .MuiTableRow-root td {
  color: #636d73;
}
.subscription-cancel-btn {
  width: 766px;
  text-align: right;
}

.for-margin-repeats {
  margin-left: 20px !important;
  padding-left: 0px !important;
  display: block !important;
}

.for-all-day-event {
  margin: 13px 0px 6px 0px !important;
}
.for-vitals-border {
  border: 1px solid lightgray;
  border-radius: 4px;
}

.for-vitals-box-height {
  height: 54px !important;
}
.for-billing-row-hgt {
  margin-top: 2px !important;
}
.for-bill-last-box {
  margin-bottom: 0px !important;
}
.for-pat-sup-bill-top {
  margin-top: 4px !important;
}
.for-service-codes-box {
  padding-top: 8px !important;
  padding-bottom: 8px !important ;
  align-items: center;
}
.for-service-code-search-result {
  height: 30px !important;
}

.for-serv-code-overflow {
  overflow: auto;
  margin: 10px;
}
.for-ndc-serv-codes {
  border: 1px solid #cbd5e0 !important;
}
.MuiTableContainer-root.for-serv-table-top-cs {
  margin-top: 16px !important;
}
.for-repeat-func-style {
  margin: 3px 0px 9px 0px !important;
}
.for-repeats-s-m-t {
  margin-bottom: 13px !important;
  margin-left: -6px;
}
.for-all-day-event-text {
  margin-right: 0px !important;
}
.for-all-day-event-text .ant-checkbox-wrapper {
  margin-right: 0px !important;
}
.for-repeat-text-checkbox {
  margin-left: -7px !important;
}
.vitals-box-overflow {
  height: 410px;
  overflow-x: hidden;
}
.for-delete-in-vitals {
  margin-left: 300px !important;
}
.for-servoce-delete-icon {
  margin-left: 396px !important;
}
.tab_title_name_appointment {
  font-family: var(--font-work-sans);
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #2e2c34;
}
.tab_title_main-app {
  padding-top: 12px;
  padding-left: 20px;
  padding-bottom: 20px !important;
}
.future-past-app-title {
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  border-radius: 6px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 11px;
  margin-top: 20px;
}
.for-past-future-box-head {
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--button-background-color);
  text-align: left;
  margin-top: -4px;
  margin-left: -1px;
  padding-left: 21px !important;
  padding-top: 20px !important;
  padding-bottom: 6px !important;
}
.for-bor-btm-none-in-ndc {
  margin-bottom: 0px !important;
}
.for-edit-app-insert-app .ant-picker-suffix {
  margin-top: -2px;
}
svg.apt-edit-time-icon {
  margin-top: 4px;
}
.for-edit-app-insert-app .ant-picker {
  border: none;
  width: 121px;
  margin: 0px 0px 0px 1px;
  height: 28px !important;
}
.for-add-pay-mod-foot {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
/* Vanitha 17-05-22 */

.pat_flag_savealt {
  padding-top: 4px !important;
}

/* Vanitha 17-05-22 */
/*Ahamed_Changes_17-05-22*/
#add-appt-prof .cus-appt-pro-title {
  padding-top: 25px !important;
  padding-bottom: 42px !important;
}
.client-popup.pat-pay-tab #postdate {
  width: 180px !important;
  margin-left: 22px !important;
}
.client-popup.pat-pay-tab #amountval {
  margin-left: 18px !important;
}
.client-popup.pat-pay-tab .form-row {
  padding-left: 25px !important;
}
.client-popup.pat-pay-tab .for-add-pay-mod-foot {
  padding-top: 16px !important;
  padding-bottom: 17px !important;
}
/*Ahamed_Changes_23-05-22*/
.client-popup.pat-pay-tab .modal-title {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}
/*Ahamed_Changes_23-05-22*/

/*Ahamed_Changes_26-05-22*/
.bg-clr-spin {
  background-color: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 2;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
}

.loader_ {
  width: 4rem;
  height: 4rem;
}

.spin {
  background: #cbd8fb;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  z-index: 2;
  -webkit-animation: spin2 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spin > div:not(.spin__blocker) {
  width: 50%;
  height: 50%;
  z-index: -1;
}
.spin__blocker {
  position: absolute;
  top: 0;
  left: 0;
  top: 3%;
  left: 7%;
  background: white;
  width: 90%;
  height: 90%;
  border-radius: 50%;
}
.spin__bottom-left {
  position: absolute;
  top: 50%;
  left: 0;
  background: -webkit-linear-gradient(
    top left,
    rgba(85, 127, 245, 1) 0%,
    rgb(85, 127, 245) 35%
  );
  background: linear-gradient(
    to bottom right,
    rgba(85, 127, 245, 1) 0%,
    rgb(85, 127, 245) 35%
  );
}
.spin__bottom-right {
  position: absolute;
  top: 50%;
  left: 50%;
  background: -webkit-linear-gradient(
    to top right,
    rgb(85, 127, 245) 15%,
    rgb(85 127 245 / 35%) 100%
  );
  background: linear-gradient(
    to top right,
    rgb(85, 127, 245) 15%,
    rgb(85 127 245 / 35%) 100%
  );
}

.spin__top-left {
  position: absolute;
  top: 0;
  left: 50%;
  background: -webkit-linear-gradient(
    to bottom right,
    rgb(85 127 245 / 0%) 0%,
    rgb(110 135 245 / 42%) 100%
  );

  background: linear-gradient(
    to bottom right,
    rgb(85 127 245 / 0%) 0%,
    rgb(110 135 245 / 42%) 100%
  );
}

.spin__blocker {
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
}

.spin-img {
  /* width: 25px;
  height: 25px; */
  display: flex;
  z-index: 2;
  position: fixed;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
}

.add-tk-category .modal-body {
  max-height: 450px;
  overflow: auto;
}

/*Ahamed_Changes*/
.appt-fix-height .modal-dialog {
  height: 345px !important;
}
/*Ahamed_Changes*/
.modal-body2 {
  overflow: auto;
  height: 380px;
  padding-left: 16px;
  padding-right: 16px;
}

/*dhilip_Changes start*/
#new_appt_radio .ant-checkbox-wrapper-disabled .ant-checkbox-inner::after {
  background-color: #ededed !important;
}

#new_appt_radio .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #ededed;
  border-color: #ededed !important;
}

#add_payment .errormessage {
  text-align: right;
  padding-right: 91px;
}
#add_payment .post_payment {
  margin-left: -40px !important;
}
#add_payment .refer_no {
  text-align: left;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 0em;
}

/*dhilip_Changes end*/
#addProblemInput .search_width .results {
  width: 97%;
}
.apptservicedetail_tickicon {
  font-size: 30px;
}
.servicedetails_tick_btn {
  border: none !important;
  color: var(--button-background-color) !important;
}

.servicedetails_tick_btnalt {
  margin-right: 10px !important;
}
.form_library_preview .formio-read-only .main_column_vozo {
  border: 1px solid #cbd5e0;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.form_library_preview
  .formio-read-only
  .main_column_vozo
  .col-md-6:first-child {
  border-right: 1px solid #cbd5e0;
}
.form_library_preview .formio-read-only .main_column_vozo .form-group {
  /* border-bottom: 1px solid #cbd5e0; */
  padding-left: 15px;
  margin-right: 20px;
}
.form_library_preview
  .formio-read-only
  .main_column_vozo
  .vozo_single_select
  .form-group {
  padding-left: 0px;
}
.form_library_preview .formio-read-only .main_column_vozo .vozo_form_header {
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 0px;
  margin-bottom: 0px !important;
  height: 82px;
}
.form_library_preview
  .formio-read-only
  .main_column_vozo
  .vozo_form_sub_header {
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 0px;
  margin-bottom: 0px !important;
  height: 82px;
}
.form_library_preview .formio-read-only .main_column_vozo .vozo_switch {
  padding-top: 6px;
  padding-bottom: 6px;
  height: 82px;
}
.form_library_preview
  .formio-read-only
  .main_column_vozo
  .vozo_multiple_select {
  padding-top: 6px;
  padding-bottom: 6px;
  height: 82px;
}
.form_library_preview
  .formio-read-only
  .main_column_vozo
  .formio-component-signature {
  padding-top: 6px;
  padding-bottom: 6px;
  height: 82px;
}
.form_library_preview
  .formio-read-only
  .main_column_vozo
  .vozo_single_select
  .form-group {
  border-bottom: 0px solid #cbd5e0;
}
.form_library_preview .formio-read-only .main_column_vozo .vozo_single_select {
  padding-top: 6px;
  padding-bottom: 6px;
  height: 82px;
}
.form_library_preview .formio-read-only .main_column_vozo .vozo_yesno {
  padding-top: 6px;
  padding-bottom: 6px;
  height: auto;
}

.form_library_preview
  .formio-read-only
  .main_column_vozo
  .col-md-6
  .form-group:last-child {
  border-bottom: 0px solid #cbd5e0;
}

.form_library_preview
  .formio-read-only
  .main_column_vozo
  .vozo_multiple_select
  .form-group {
  border-bottom: 0px solid #cbd5e0;
  padding-left: 0px;
  margin-top: 2px;
}
.form_library_preview
  .formio-read-only
  .main_column_vozo
  .vozo_multiple_select
  .form-group
  .choices__input {
  background-color: #ffffff !important;
}
.form_library_preview
  .formio-read-only
  .main_column_vozo
  .vozo_multiple_select {
  padding-top: 6px;
  padding-bottom: 6px;
  height: 82px;
}

.form_library_preview
  .formio-read-only
  .main_column_vozo
  .formio-component-textfield {
  padding-top: 6px;
  padding-bottom: 6px;
  height: 82px;
}

.form_library_preview
  .formio-read-only
  .main_column_vozo
  .vozo_fractional_field {
  padding-top: 6px;
  padding-bottom: 6px;
  height: 82px;
}
.form_library_preview
  .formio-read-only
  .main_column_vozo
  .vozo_yesno
  .form-check-inline
  label {
  padding: 5px 24px;
}
.form_library_preview
  .formio-read-only
  .main_column_vozo
  .vozo_single_select
  .ui.fluid.dropdown {
  padding-top: 5px;
  padding-bottom: 4px;
  line-height: 21px;
  width: 220px !important;
}
.form_library_preview .formio-read-only .main_column_vozo .col-form-label {
  font-size: 14px;
  color: #2d3748;
  font-weight: 600;
}
.form_library_preview
  .formio-read-only
  .main_column_vozo
  .vozo_switch
  .form-check {
  padding-top: 4px;
}
.form_library_preview
  .formio-read-only
  .main_column_vozo
  .vozo_single_select
  .selection {
  margin-top: 4px;
}
.form_library_preview
  .formio-read-only
  .main_column_vozo
  .vozo_yesno
  .col-form-label {
  padding-bottom: 8px;
}
.form_library_preview
  .formio-read-only
  .main_column_vozo
  .formio-component-shortTextField
  label {
  padding-bottom: 13px;
}
.form_library_preview .formio-read-only .main_column_vozo .form-group input {
  width: 220px;
}
/*dhilip_Changes end*/
/* yes no comp */

.form-check-inline .form-check-input {
  height: 16px;
  width: 16px !important;
  -webkit-appearance: none;
  /* border: 2px solid black; */
  border: 1px solid #e2e8ed;
  margin-top: 4px !important;
  border-radius: 2px;
}
.form-check-inline .form-check-input:checked {
  background-image: url("../../assets/images/form_builder_yes_no_check.png");
  background-repeat: no-repeat;
  background-size: contain;
}

/* jeyabalaji start  */
.form-check-inline .form-check-input {
  height: 16px;
  width: 16px !important;
  -webkit-appearance: none;
  /* border: 2px solid black; */
  border: 1px solid #e2e8ed;
  margin-top: 4px !important;
  border-radius: 2px;
}
.form-check-inline .form-check-input:checked {
  background-image: url("../../assets/images/form_builder_yes_no_check.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.vozo_fractional_field table tr:nth-child(2) td:nth-child(1) {
  background-image: url("../../assets/images/form_builder_fraction_line.png");
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
}
.vozo_fractional_field table tr:nth-child(2) td:nth-child(1) input {
  margin-left: 24px !important;
  margin-top: -2px !important;
}

.vozo_fractional_field table tr:nth-child(2) {
  padding-left: 8px !important;
  margin-top: -4px !important;
}

.formcomponents .card-body .formcomponent i {
  display: none;
}

.formcomponents .builder-sidebar_scroll .card-body span:nth-child(1) {
  background-image: url("../../assets/images/form_builder_new_text.png");
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background-position: 12px;
}
.formcomponents .builder-sidebar_scroll .card-body span:nth-child(2) {
  background-image: url("../../assets/images/form_builder_new_text.png");
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background-position: 12px;
}
.formcomponents .builder-sidebar_scroll .card-body span:nth-child(3) {
  background-image: url("../../assets/images/form_builder_new_short_text.png");
  background-repeat: no-repeat;
  background-size: 28px 16px;
  background-position: 12px;
}
.formcomponents .builder-sidebar_scroll .card-body span:nth-child(4) {
  background-image: url("../../assets/images/form_builder_shortTextarea.png");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 12px;
}
.formcomponents .builder-sidebar_scroll .card-body span:nth-child(5) {
  background-image: url("../../assets/images/form_builder_TextareaAcross.png");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 12px;
}
.formcomponents .builder-sidebar_scroll .card-body span:nth-child(6) {
  background-image: url("../../assets/images/form_builder_large_textarea.png");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 12px;
}
.formcomponents .builder-sidebar_scroll .card-body span:nth-child(7) {
  background-image: url("../../assets/images/form_builder_new_switch.png");
  background-repeat: no-repeat;
  background-size: 26px 16px;
  background-position: 12px;
}
.formcomponents .builder-sidebar_scroll .card-body span:nth-child(8) {
  background-image: url("../../assets/images/form_builder_new_fraction.png");
  background-repeat: no-repeat;
  background-size: 39px 16px;
  background-position: 12px;
}
.formcomponents .builder-sidebar_scroll .card-body span:nth-child(9) {
  background-image: url("../../assets/images/form_builder_new_yes_no.png");
  background-repeat: no-repeat;
  background-size: 34px 16px;
  background-position: 12px;
}
.formcomponents .builder-sidebar_scroll .card-body span:nth-child(10) {
  background-image: url("../../assets/images/form_builder_new_multiple.png");
  background-repeat: no-repeat;
  background-size: 36px 16px;
  background-position: 12px;
}
.formcomponents .builder-sidebar_scroll .card-body span:nth-child(11) {
  background-image: url("../../assets/images/form_builder_new_single.png");
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: 12px;
}
.formcomponents .builder-sidebar_scroll .card-body span:nth-child(12) {
  background-image: url("../../assets/images/form_builder_new_signature.png");
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: 12px;
}
.formcomponents .builder-sidebar_scroll .card-body span:nth-child(13) {
  background-image: url("../../assets/images/form_builder_new_calendar.png");
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: 12px;
}
.formcomponents .builder-sidebar_scroll .card-body span:nth-child(14) {
  background-image: url("../../assets/images/form_builder_new_checkbox.png");
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: 12px;
}
.formcomponents .builder-sidebar_scroll .card-body span:nth-child(15) {
  background-image: url("../../assets/images/form_builder_radiobox.png");
  background-repeat: no-repeat;
  background-size: 20px 16px;
  background-position: 12px;
}

.vozo_datepicker .input-group-append {
  display: none;
}
.vozo_datepicker .input-group {
  width: 262px !important;
  height: 38px !important;
}

.vozo_datepicker input {
  background-image: url("../../assets/images/form_builder_new_calendar.png") !important;
  background-repeat: no-repeat !important;
  background-size: 16px 16px !important;
  background-position: 230px;
}

.vozo_textarea textarea {
  width: 240px !important;
  min-height: 40px !important;
}
.vozo_textarea_long_across textarea {
  width: 100% !important;
  min-height: 40px !important;
}
.vozo_textarea_long textarea {
  width: 100% !important;
  height: 240px !important;
}
.vozo_checkbox input {
  height: 24px;
  width: 24px !important;
  -webkit-appearance: none;
  border: 1px solid #cbd5e0;
  margin-top: 4px !important;
  border-radius: 4px;
}
/* .vozo_checkbox span {
  display: none;
} */

.vozo_checkbox input:checked {
  background-image: url("../../assets/images/form_builder_yes_no_check.png");
  background-repeat: no-repeat;
  background-size: contain;
}
/* jeyabalaji end  */

/* jeyabalaji end  */

/* Vanitha 08-09-22 */

.err_msg_appt_bill {
  margin-top: -10px !important;
}

/* Vanitha 08-09-22 */

/*--------cheran--------*/
.over-flow-hide-address::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.superbill-font-family {
  font-family: var(--font-work-sans) !important;
  color: #2e2c34;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}
.table-row-super-bill .row-border-super-bill,
.table-row-super-bill .row-border-super-bill:last-child {
  border-bottom: 1px solid #cbd5e0 !important;
}
.super-bill-card-head-style .card-header {
  display: flex;
  align-items: center;
  padding: 20px;
  font-weight: 500 !important;
  color: #2d3748;
  font-family: var(--font-work-sans);
}
.super-bill-card-label {
  font-family: var(--font-work-sans);
  color: #84818a;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0px;
  padding-bottom: 16px;
}
.table-row-super-bill .MuiTableRow-root.MuiTableRow-head th {
  color: #000000 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  background-color: #f8f8f8 !important;
}
.table-row-super-bill .MuiTableRow-root td {
  color: #2d3748 !important;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}
.sup-bill-foot-style {
  font-family: var(--font-work-sans);
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
}
.sup-list-items .group_title_text {
  font-size: 14px !important;
  font-family: var(--font-work-sans);
}
.refmanage .ant-tabs-nav-list {
  padding-left: 20px !important;
  height: 46px;
}
.refmanage .ant-tabs-tab-btn {
  color: #718096 !important;
  font-family: var(--font-work-sans);
  font-weight: 400;
  font-size: 16px !important;
  line-height: 19px !important;
}
.refmanage .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--button-background-color) !important;
  font-family: var(--font-work-sans);
  font-weight: 400;
  font-size: 16px !important;
  line-height: 19px !important;
}
.ref-manage-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #718096;
  font-family: var(--font-work-sans);
}
.ui-search-refmanage .ui.icon.input > input {
  width: 200px !important;
  background-color: #ffffff !important;
  color: #718096 !important;
  padding: 7.5px 6px !important;
}
.ui-search-refmanage .ui.icon.input > i.icon {
  left: auto !important;
  right: 6px !important;
  color: #718096;
}
.ui-search-refmanage .ui.search > .results {
  width: 200px;
}
.ui-search-refmanage .ui.search > .results .title {
  color: #718096;
}
.ui-search-refmanage .ui.icon.input > i.icon:before {
  color: #718096 !important;
}
@media only screen and (max-width: 1119px) {
  .ref-manange-resp-filter-btn {
    margin-top: 0px !important;
    margin-bottom: 20px !important;
  }
}

.table-row-super-bill .super-bill-no-border-btm {
  border-bottom: none !important;
}
.sup-bill-bck-chnge .modal-header .close {
  background-color: #ffff !important;
}

/* Vanitha 21-11-22 */

.passinput input {
  height: 25px !important;
}

.passinput span {
  height: 36px !important;
}

.passinput span span {
  margin-top: -6px !important;
}

.confpassinput input {
  height: 25px !important;
}

.confpassinput span {
  height: 36px !important;
}

.confpassinput span span {
  margin-top: -6px !important;
}
.passinput {
  width: 50%;
  margin-left: 9px;
  margin-right: 6px;
}
.confpassinput {
  width: 50%;
  margin-left: 9px;
  margin-right: 3px;
}
/* Vanitha 21-11-22 */

/* Vanitha 21-11-22 */

.mod_body_accessdenied {
  display: flex !important;
  height: 510px !important;
  padding: 20px !important;
}
/*-------jeyabalaji 18-11-2022 -------*/
.add_user_cnfrm_pwd input {
  height: 26px !important;
}
/*-------jeyabalaji 18-11-2022 -------*/

/* Vanitha 28-11-22 */

.new_appt_btn_alt {
  padding-top: 16px !important;
  padding-bottom: 17px !important;
}

/* Vanitha 28-11-22 */

.table-row-super-bill .sup-list-items {
  height: max-content !important;
}
.suber_bill_table .sup-list-items .MuiTableCell-sizeSmall {
  padding: 16px 24px 0px 24px !important;
}

/* Vanitha 13-12-22 */
.em-check .ant-checkbox .ant-checkbox-inner {
  margin-right: 0px !important;
}

.add-contact-form .delete_icon {
  margin-left: 10px !important;
}

/* Vanitha 13-12-22 */

/* Vanitha 14-12-22 */
.alt_add_med .search_input .input {
  width: 107% !important;
}

/* Vanitha 14-12-22 */

/* Vanitha 15-12-22 */

.med_mod_body_alt1 select {
  padding-right: 35px !important;
}
/* Vanitha 15-12-22 */
/* cheran 13-12-22 */
.ant-checkbox-disabled-color
  .ant-checkbox-wrapper-disabled
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--button-background-color) !important;
  border-color: var(--button-background-color) !important;
}
/* cheran 13-12-22 */
.New-App_Mod-Title {
  margin: 13px 0px 13px 27px;
}
.New-App_Mod-Body {
  padding: 0px !important;
}
.invoice-pointer {
  cursor: pointer;
  color: blue;
}

.add_pay_dateerr {
  margin-left: -60px !important;
}

.epharm_alt {
  display: flex !important;
}
.table-data-row td.temp-name-color {
  color: #2e2e2e;
}
.table-data-row td.temp-assigned-color {
  color: var(--button-background-color);
}
.table-data-row td.temp-data-color {
  color: #636d73;
}
.tk-md-bd-overflow .tk-nt-mb-10 {
  margin-bottom: 10px;
}
.ad-tk-pat-srch div.all-flex-center-between {
  width: 394px;
}
.rel-item-inp .input-h-32 {
  height: 32px !important;
}
.tk-cd-indctr button.tk-btn-w-80 {
  width: 80px;
}
.ed-ct-arc-btn svg.ct-arc-icon {
  margin: 0px 5px 2.33px 0px;
}
.st-ed-full .tsk_stat-btn_cls {
  margin-right: 2px;
}
.schedule-body-row .tk-st-d {
  display: block;
}
.schedule-body-row .tk-st-inp-1 {
  display: none;
  width: 130px;
}
.schedule-body-row .tk-st-inp-2 {
  display: none;
  width: 100px;
}
.schedule-body-row .tk-st-inp-3 {
  display: none;
  width: 120px;
}
.st-ed-btn-place .tk-st-pdl-0 {
  padding-left: 0px;
}
.tk-tem-p-20 {
  padding: 20px;
}
.temp-lbl-size .temp-due-dt-wid {
  width: 300px;
  margin-right: -25px !important;
}
.tk-tem-p-20 .tk-temp-not {
  height: 78px;
  width: 275px;
}
.tk-ed-indictr button.tk-tem-arc-btn {
  width: 80px;
}
.temp-lbl-size .temp-due-dt-err {
  margin-left: -13px;
  margin-top: 2px;
}
/* saranya inline css start 18-04-23 */
.phar_title {
  margin-top: 0px !important;
}
.rxpro_tb thead {
  background-color: #fafafa;
}

.fav_phar_body {
  padding: 10px 0px 20px;
  overflow: auto;
}
.fav_phar_mail {
  margin-bottom: 14px;
  margin-left: 6px;
}

.fav_ret_btn {
  margin-left: 2px !important;
  margin-right: 2px;
}

.mid-top div {
  margin-bottom: 16px;
  margin-left: 8px;
}

.fav_icon {
  color: blue !important;
}

.fav_item {
  margin-left: 18px;
}
.remeditcolor {
  background-color: #fafafa;
}
.phar_daw_err {
  margin-left: 20px;
}

/* saranya inline css end 18-04-23 */
/*------cheran----------*/
.suber_bill_table td {
  font-family: var(--font-work-sans);
}
.p-b-0 {
  padding-bottom: 0px;
}
.pad-0 {
  padding: 0px !important;
}
.text-cen {
  text-align: center !important;
}
.p-t-0 {
  padding-top: 0px !important;
}
.w-350 {
  width: 350px !important;
}
.m-r-0 {
  margin-right: 0px !important;
}
.m-l-0 {
  margin-left: 0px;
}
.address-scroll-hide .over-flow-hide-address {
  height: 89px;
  overflow-y: scroll;
  padding: 0px 2px 0px 2px;
  width: 178px;
}
.w-175 {
  width: 175px !important;
}
.m-l-neg6 {
  margin-left: -6px !important;
}
#new-appts .pl-9 {
  padding-left: 13px !important;
}
.pl-9 .MuiGrid-item {
  margin-left: -6px !important;
  margin-right: 25px !important;
  padding-left: 0px !important;
}
.m-l-29 {
  margin-left: 29px !important;
}
.pl-9 .switch-app {
  margin-left: -6px !important;
}
.in-logo-exm1 {
  margin-top: 0px;
  margin-left: 0px;
  height: max-content !important;
  max-height: 72px !important;
}
.add_app_mod3 .p-l-0 {
  padding-left: 0px !important;
}
.flex-end-tasks {
  justify-content: flex-end;
}
.antd-select-height
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 34px !important;
  border-radius: 4px !important;
}
.antd-select-height1 .ant-select-arrow {
  color: #636d73;
  font-size: 9px;
  top: 53%;
}
.antd-select-height2 .ant-select-arrow {
  color: #636d73;
  font-size: 9px;
  top: 53%;
}
.antd-select-height .ant-select-arrow {
  color: #636d73;
  font-size: 14px;
  top: 45%;
}
.antd-select-height1
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 38px !important;
  border-radius: 4px !important;
}
.blue-color-inv {
  color: blue !important;
}

.pad-inv-apd {
  padding: 11px 20px 11px 16px;
}
.new_appt_detail .date_space {
  margin-right: 8px;
}
/*------cheran----------*/
/* saranya start  newappointment 19-04-23 */

.allday {
  margin-top: -20px !important;
  margin-left: -9px;
}
.addapprepeat {
  margin-left: -2px !important;
}
.switchapp_alt {
  margin-left: 5px;
}

.wholediv {
  display: flex;
  margin-left: 28px;
  margin-top: -4px;
}

.access_icon {
  margin-top: -8px;
}

.dropdiv {
  margin-left: 19px;
  margin-bottom: 14px;
  position: relative;
}
.dropdiv .ant-select {
  width: 284px !important;
}
.dropdiv1 .ant-select {
  width: 285px !important;
}

.downDropDown .ant-select-dropdown {
  left: 0% !important;
  top: -143px !important;
}
.dropdiv1 {
  margin-left: 14px;
  margin-bottom: 14px;
}

.wholediv1 {
  display: flex;
  margin-left: 17px;
  margin-top: 2px;
}
.wholediv2 {
  display: flex;
  margin-left: 19px;
  margin-top: 2px;
}

.app_det_check {
  display: flex;
  margin: -21px 0 19px 39px;
}

.repeatapptem {
  display: flex;
  margin: -9px 0 0 -9px;
}

.selet_label {
  font-size: 16px;
  color: #718096;
  margin-left: 28px;
  margin-bottom: 15px;
  margin-top: -8px;
}
.appointment_patient_div {
  display: flex;
  margin-left: 28px;
  margin-top: -4px;
}

.appointment_drop_div {
  width: 284px !important;
  margin-left: 21px;
}
.app_rep {
  margin-left: 21px;
}

.appointment_popup_div {
  margin-left: 10px;
  margin-top: -8px;
}
/* saranya  end  newappointment 19-04-23 */
/*vignesh inline css 17-02-23*/
.InvoiceEmail-spinner {
  border: 50px;
  padding: 30px;
}

.SuperBillsEmail-spinner {
  justify-content: center;
}
.SettingAddRoles-modalmsg {
  width: 500px !important;
}

.loading-popup {
  position: "absolute";
  top: "31%";
  text-align: center;
  font-family: var(--font-work-sans);
  font-size: 18px;
}
/*vignesh inline css 17-02-23 */

/* jeyabalaji 21-04-2023 */
.err_msg_for_pat_flag {
  padding-left: 50px;
}
.add_lab_w_d .add_lab_mod_tit {
  margin-top: 0px;
}
.add_lab_w_d .add_lab_spinner_val_s {
  height: 380px;
  display: grid;
  place-items: center;
}
.add_lab_w_d .lab_s_f_r_mt {
  margin-top: 20px;
}
.add_lab_w_d .lab_fr_s_mb {
  margin-bottom: 2px;
}
.add_lab_w_d .lab_fr_s_pt {
  padding-top: 28px !important;
}
.add_lab_w_d .lab_fr_s_hgt {
  height: 65px;
}
.add_lab_w_d .lab_add_save_btn {
  height: 32px;
  width: 79px;
}
.add_lab_w_d .lab_add_alrt_msg_s {
  width: 400px;
}
.add_lab_w_d .lab_add_style_mb {
  margin-bottom: 4px;
}
.add_lab_w_d .lab_add_s_fs_al {
  font-size: 20px;
}
/* jeyabalaji 21-04-2023 */
/*vanitha inline css 21-04-2023 */

.add_app_prof_consent {
  width: 260px !important;
}

.add_app_prof_pop1 .modal-header {
  align-items: center !important;
  padding: 20px !important;
}

.add_app_prof_pop1 .modal-footer {
  padding: 16px;
}
.add_app_prof_pop1 {
  padding-bottom: 1px;
  padding-top: 2px;
}

.add-appt-prof-pop .modal-body .add_app_prov_spin {
  height: 310 !important;
  display: grid !important;
  place-items: center !important;
}

.add_app_prof_name p {
  margin-left: 150px !important;
  margin-top: 0px !important;
}

.add_app_width_ctrl {
  width: 262px !important;
}

.add_app_prof_name input {
  width: 111% !important;
}

.add_app_prov_dur {
  margin-left: 0px !important;
}

.add_prof_grp_form .add_app_prov_min {
  width: 80px !important;
}

.add_prof_grp_form .mb-3 {
  width: 100%;
  height: 40px;
}

.add_app_prof_pop1 .form-row {
  margin-right: -4px;
}

.app_profile_btn_padd {
  padding-left: 15px;
}
/*vanitha inline css 21-04-2023 */

/* // nisha start // */

.h-168 {
  height: 168px !important;
}
.e-msg {
  margin-left: 153px;
  margin-bottom: 11px !important;
  margin-top: 0px !important;
}
.p-input {
  width: max-content;
  height: 43px;
  padding-left: 4px;
}
.w-272 {
  width: 272px !important;
}
.w-262 {
  width: 262px !important;
}
.h-m-c {
  height: max-content !important;
}
.p-t-14 {
  padding-top: 14px !important;
}
.p-l-23 {
  padding-left: 23px !important;
}
.p-l-28 {
  padding-left: 28px !important;
}
.w-5 {
  width: 5% !important;
}
.w-135 {
  width: 135px !important;
}
.bill-up-btn {
  width: 100% !important;
  height: 32px !important;
  margin-top: 2px !important;
  margin-right: 5px !important;
}
.w-40px {
  width: 40px;
}
.h-33 {
  height: 33px;
}
.colour-red {
  color: red !important;
}
.CPT-4-codeupadte {
  width: 74px;
  height: 33px;
  text-align: center;
  padding-top: 8px;
}
.CPT-4ndc-select {
  cursor: pointer;
  width: 70px;
  display: block;
}
.CPT-4ndc-delete {
  cursor: pointer;
  color: red;
  width: 70px;
  display: none;
}

.d-none {
  display: none !important;
}
.w-180 {
  width: 180px !important;
}
.over-fx-unset {
  overflow-x: unset;
}
.cust-code-update {
  width: 74px;
  height: 30px;
  text-align: center;
  padding-top: 8px;
}
.p-l-18 {
  padding-left: 18px !important;
}
.HSPCS-CodeUpdate {
  width: 70px;
  height: 33px;
  text-align: center;
  padding-top: 8px;
}
.h-380 {
  height: 380px !important;
}
.d-grid {
  display: grid;
}

.h-68 {
  height: 68px !important;
}
.form-group-newpro {
  width: max-content;
  margin-bottom: 20px !important;

  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 4px;
}
.er-bil-alert {
  margin-bottom: 10px !important;
}
.w-327 {
  width: 327px !important;
}
.w-337 {
  width: 337px !important;
}
.w-319 {
  width: 319px !important;
}
.w-303 {
  width: 303px !important;
}

/* // nisha end// */

/* Add billinf profiles */
/* nisha start */
.billing-pro
  .for_autoComplete_bi
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 100%;
  height: 32px;
  padding: 0 11px;
}
.billing-pro
  .for_autoComplete_bi
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px !important;
}

/* nisha end  */

/* Vanitha 20-02-23 */
.add_prblm_err_msg {
  margin-top: -10px !important;
  margin-left: 6px !important;
}
.prblm_snomed_ {
  margin-top: 5px !important;
}

/* Vanitha 20-02-23 */
/* vignesh 25-4-23 */
.err_problem_type_sel {
  margin-top: -1px !important;
  margin-left: 6px !important;
}

.Allegry-search .for_autoComplete_bi .ant-select-selection-placeholder {
  color: gray !important;
  /* padding-top: 1px; */
}

/* vignesh 25-4-23 */
/* vignesh 26-4-23 */

.error_message_margin {
  margin-top: -12px !important;
}
/* vignesh 26-4-23 */
/* jeyabalaji 28-04-2023 */
.for_service_add_a_c {
  width: 617px;
  height: 28px;
}
.for_service_add_a_c .for-service-codes-bground {
  /* width: 100%; */
  width: 98%;
  margin-left: auto;
  margin-right: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.for_service_add_a_c .ant-select-selector {
  height: 28px !important;
  align-items: center;
}
.for_service_add_a_c .ant-select-selection-placeholder {
  margin-top: 3px;
}
.for_service_add_a_c svg {
  /* margin-top: 10px !important; */
  z-index: 1 !important;
}
.for_a_c_s_a_t_ndc {
  width: 230px;
  height: 38px;
  padding-left: 0px !important;
}
.for_a_c_s_a_t_ndc .ant-select-selector {
  height: 38px !important;
}
.for_a_c_s_a_t_ndc .ant-select-selection-placeholder {
  margin-top: 3px;
}
.for_a_c_s_a_t_ndc svg {
  margin-top: 10px !important;
  z-index: 1 !important;
}
.for_serv_add_ndc_right {
  margin-right: 16px !important;
  border: none;
  background-color: #fff;
}
.for_s_t_a_c {
  width: 100%;
}
/* jeyabalaji 28-04-2023 */
img.pass-alert-rect-img {
  border-radius: 8px;
}
.pas-exp-mod-color {
  color: var(--button-background-color);
}
.pass-exp-form .row {
  margin-left: -14px;
}
.pass-exp-form .errormessage {
  margin-left: 14px;
}
.pass-exp-form .form-group.col {
  margin-bottom: 8px !important;
}
.pas-exp-can-btn.btn-light {
  height: 34px;
  margin-top: 6px;
  border-radius: 5px;
}
.pas-exp-save-btn.btn-primary {
  margin-top: 6px;
  margin-bottom: 6px;
}
.user-guide-color {
  color: #3f8cff !important;
}
.user-guide-style-1 {
  color: #3f8cff;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.user-guide-style-2 {
  color: #3f8cff;
  margin-left: 5px;
  cursor: pointer;
}
.vozo-load-head {
  position: absolute;
  top: 31%;
  text-align: center;
  font-family: var(--font-work-sans);
  font-size: 18px;
}
.mr-t-6 {
  margin-top: 6px;
}
.upg-req-modal.btn-light {
  margin: 6px 16px 6px 0px;
  border: 1px solid var(--button-background-color);
  color: var(--button-background-color);
  font-family: var(--font-work-sans) !important;
  font-size: 14px !important;
  background-color: #fff !important;
}
.upg-req-btn.btn-primary {
  margin: 6px 0px;
  font-family: var(--font-work-sans) !important;
  font-size: 14px !important;
}
.sub-exp-warn-icon {
  margin: 0px 4px 4px 0px;
}
.sub-exp-btn.btn-primary {
  height: 32px !important;
}
.welcome-scrn {
  height: 150vh;
}
.mr-l-16 {
  margin-left: 16px;
}
.wel-mod-vid-scrn {
  width: 100%;
  height: 75vh;
}

.ad-tk-pat-srch div.all-flex-center-between {
  width: 100%;
}
.rel-item-inp.antd-select-height {
  width: 252px;
}
.pat-srch-val-tk .ant-select-item-option-content {
  border-bottom: none !important;
}
.adapp-brd-none .ant-select-item-option-content {
  border-bottom: none !important;
}
/* vignesh 04-05-23 */

.p-l-17 {
  padding-left: 17px;
}
/* vignesh 04-05-23 */
.for_prov_ava_ex_room {
  margin-left: -10px;
  width: 105%;
}
.err_msg_prov_ava {
  margin-left: 15px;
}
.for-delete_icon_m_r {
  margin-right: 16px;
}
.vw-tk-ed-btn {
  color: var(--button-background-color);
  margin-left: 35px;
  cursor: pointer;
}
.tk-single-check {
  margin-right: 15px;
  margin-top: -30px;
  padding-left: 0px;
}
.tk-single-check input {
  height: 0px;
}
.ed-temp-table {
  border: 1px solid #cbd5e0 !important;
  border-radius: 4px;
  margin: 0 40px;
}
/*--- cheran  start ---*/
.cal-mod .ant-select-selection-item {
  padding-top: 3px;
}
.m-b-0-imp {
  margin-bottom: 0px !important;
}
.d-none1 {
  display: none;
}
/*--- cheran  end ---*/
tbody.styl-tbl-body-row tr:not(:last-child) {
  border-bottom: 1px solid #cbd5e0 !important;
}
.add_app_mod3 .error_border .ant-select-selector {
  border: 1px solid red !important;
  border-radius: 5px !important;
}
.add_app_mod2 .error_border .ant-select-selector {
  border: 1px solid red !important;
  border-radius: 5px !important;
}
.dropdiv
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector:hover {
  background-color: #f1f3f4 !important;
  border-radius: 4px !important;
  border-color: rgba(34, 36, 38, 0.35) !important;
}
.appointment_drop_div
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector:hover {
  background-color: #f1f3f4 !important;
  border-radius: 4px !important;
  border-color: rgba(34, 36, 38, 0.35) !important;
}
.template_hover
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector:hover {
  border-radius: 4px !important;
  border-color: rgba(34, 36, 38, 0.35) !important;
}
tbody.styl-tbl-body-row tr:not(:last-child) {
  border-bottom: 1px solid #cbd5e0 !important;
}

.rel-item-inp.row {
  width: 274px;
}
.ad-tk-patient {
  width: 240px;
}
/* saranya table 08-05-23 */

.procedure_container {
  border-radius: 4px !important;
  margin-top: 0px !important;
  border: 1px solid #ebeaed;
  border-collapse: collapse;
}
.pdr-br-lft {
  border-left: 1px solid #ebeaed !important;
}
.prd-order-result td.p-l-16 {
  padding-left: 12px !important;
}
/* saranya table 08-05-23 */
.temp-back-btn {
  margin-top: 2px;
}
/* saranya facility dropdown  27-04-23 */
.arrow-provider {
  -webkit-appearance: none !important;
  background-image: url("../../assets/images/Down_Arrow.png");
  background-repeat: no-repeat;
  background-position: right 13px top 15px;

  /* color: #2347e7 !important; */
  color: var(--button-background-color) !important;
  line-height: 1;
}
.arrow-provider-tick {
  background-image: url("../../assets/images/Tickmark.png");
  background-repeat: no-repeat;
  background-position: right 12px top 16px;

  line-height: 1;
}
#cal_drop_arrow {
  background-image: url("../../assets/images/Down_Arrow.png");
  background-repeat: no-repeat;
  background-position: right 7px top 15px;
  line-height: 1;
}
#cal_fac_arrow {
  background-image: url("../../assets/images/Down_Arrow.png");
  background-repeat: no-repeat;
  background-position: right 13px top 15px;
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 20px;
}

.cal_drop_arrow1 .dropdown-item:hover,
.dropdown-item:focus {
  color: #40a9ff;
  text-decoration: none;
  background-color: #f1f5f8 !important;
}
.facilitynav .dropdown-item:hover,
.dropdown-item:focus {
  color: #40a9ff;
  text-decoration: none;
  background-color: #f1f5f8 !important;
}
.facilitynav .dropdown-menu {
  min-width: 123px;
}
.cal_drop_arrow1 .dropdown-menu {
  min-width: 20px;
}
.cal_drop_arrow1 .dropdown-item {
  padding: 5px 10px 5px 10px !important;
}

/* saranya facility dropdown  27-04-23 */
.appt-det-full-screen-btn {
  background-color: white;
  color: #2e2e2e;
  border: 1px solid #2e2e2e;
  padding: 8px 10px !important;
}
.appt-det-full-screen-btn:not(.move) {
  margin-left: auto !important;
}
.appt-det-full-screen-btn.move {
  margin-left: 20px !important;
}
.appt-det-full-screen-btn.btn.btn-primary:hover {
  background-color: white !important;
  color: #2e2e2e !important;
  border: 1px solid #2e2e2e !important;
}
.appt-det-full-screen-btn svg {
  height: 16px;
  width: 16px;
  margin-top: -4px;
}
.tele-call-btn.btn.btn-success {
  height: 39px;
  background-color: #28a745;
  border-color: #28a745;
  cursor: pointer;
  opacity: 1.65;
}
.tele-call-btn1.btn:not(:disabled):not(.disabled) {
  cursor: not-allowed;
  background-color: lightgreen;
  border: none;
  opacity: 0.65;
}
.appt-edit-note-btn {
  height: 38px !important;
}
.appt-edit-note-btn.join {
  margin-left: 10px !important;
}
.appt-full-popup {
  padding: 0px !important;
}
.appt-full-popup .modal-dialog {
  height: 100% !important;
  width: 100% !important;
  margin: 0 !important;
  top: 0 !important;
  max-width: 100% !important;
}
.appt-full-popup .modal-content {
  height: 100%;
}
.appt-full-popup .modal-header {
  padding: 24px 32px !important;
}
.appt-full-popup .modal-header .modal-title {
  font-size: 24px !important;
  line-height: 33px !important;
  margin-top: 0px !important;
}

.apt-full-body {
  padding: 16px 32px 14px !important;
}
.p-t-12 {
  padding-top: 12px !important;
}
.appt-full-popup .modal-body .appt_det_left {
  height: calc(100% - 80px) !important;
}
.appt-full-popup .modal-body .ant-tabs-content.ant-tabs-content-top {
  height: calc(100% - 101px) !important;
  overflow: auto !important;
}
.appt-full-popup .modal-body .new_appt_dett {
  height: 100%;
  /* width: 580px !important; */
}
.appt-full-popup.cal-mod .modal-dialog {
  margin-top: 0px !important;
}
.appt-full-popup .new_appt_detail_footer {
  padding-top: 36px !important;
  padding-bottom: 23px !important;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: white;
}
.appt-full-popup .new_appt_dett {
  margin-top: 0px !important;
  padding-top: 20px !important;
}
.appt-full-popup .new_appt_detail_footer .close-btn,
.appt-full-popup .new_appt_detail_footer .save-btn {
  height: 35px !important;
}
.appt-full-popup .collapse.show {
  height: 100% !important;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.appt-full-popup .cal-mod.modal-body {
  height: calc(100% - 81px) !important;
}
.appt-full-popup .collapse.show {
  height: calc(100% - 46px) !important;
}
.appt-full-popup .accordion button {
  background-color: white;
  border-bottom: 1px solid rgba(46, 46, 46, 0.25);
  padding: 14px 0;
}
.appt-full-popup .collapse.show .modal-body {
  border-bottom: 1px solid rgba(46, 46, 46, 0.25);
}
.appt-full-popup .accordion button:hover {
  background-color: white !important;
  box-shadow: none;
  border-color: none;
}
.bill_appt.position-relative .service-edit-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid var(--button-background-color);
  color: var(--button-background-color);
}
.bill_appt.position-relative .service-edit-button:active {
  background-color: white;
  color: var(--button-background-color);
}

/* Vanitha 24-04-23 */
.make1-ant-tabs-tab-active #appt_tabs-tab-1,
.make2-ant-tabs-tab-active #appt_tabs-tab-2,
.make3-ant-tabs-tab-active #appt_tabs-tab-3,
.make4-ant-tabs-tab-active #appt_tabs-tab-4 {
  color: #096dd9 !important;
  text-shadow: 0 0 0.25px currentColor;
}
.make1-ant-tabs-tab-active .ant-tabs-tab-active,
.make2-ant-tabs-tab-active .ant-tabs-tab-active,
.make3-ant-tabs-tab-active .ant-tabs-tab-active,
.make4-ant-tabs-tab-active .ant-tabs-tab-active {
  border-bottom: 3px solid #096dd9 !important;
}
/* Vanitha 24-04-23 */
.new_appt_detail .new_appt_dett .apt-det-part-one {
  border-bottom: 1px solid #cbd5e0;
}
.appt-full-popup .new_appt_dett .apt-det-part-one,
.appt-full-popup .new_appt_dett .apt-det-part-two {
  width: 50%;
}
.appt-full-popup .appt-det-apt-tab .ant-tabs-tab:nth-child(1) .ant-tabs-tab-btn,
.appt-full-popup
  .appt-det-serv-tab
  .ant-tabs-tab:nth-child(2)
  .ant-tabs-tab-btn,
.appt-full-popup
  .appt-det-bill-tab
  .ant-tabs-tab:nth-child(3)
  .ant-tabs-tab-btn,
.appt-full-popup
  .appt-det-vit-tab
  .ant-tabs-tab:nth-child(4)
  .ant-tabs-tab-btn {
  color: var(--button-background-color);
}
.appt-full-popup .appt-det-apt-tab .ant-tabs-tab:nth-child(1),
.appt-full-popup .appt-det-serv-tab .ant-tabs-tab:nth-child(2),
.appt-full-popup .appt-det-bill-tab .ant-tabs-tab:nth-child(3),
.appt-full-popup .appt-det-vit-tab .ant-tabs-tab:nth-child(4) {
  border-bottom: 3px solid var(--button-background-color);
}
.newModal .modal-dialog {
  width: 357px;
  margin-right: 37%;
  box-shadow: 0px 1px 8px rgb(20 46 110 / 20%) !important;
}
.repeat_every_app {
  display: flex;
}
.repeat_drop_app {
  width: 194px;
  height: 38px;
}
.repeat_every_app .form-control {
  width: 96px;
  height: 35px;
  margin: 10px 10px 10px 10px;
}
.repeat_every_app .repeat-form-control {
  width: 48px;
  text-align: center;
  -webkit-appearance: none;
  background-repeat: no-repeat;
  background-position: right 8px top 8px;
  line-height: 1 !important;
}
.newModal .modal-title {
  margin: 14px 0px 0px 16px;
}

.repeat_every_app .p {
  margin: 10px 10px 10px 32px;
}
.radio_appt_btn1 {
  color: #718096;
  display: block !important;
}
.rep-date1 .ant-picker {
  border-radius: 4px;
}

.rep-date1 .select_app_date {
  width: 108px !important;
  margin: 0px 0px 0px 64px;
  font-size: 7px;
}

.rep-date2 .select_app_date1 {
  margin-top: 0px !important;
  width: 62px !important;
  border: 0px !important;
}

.rep_btn_app .btn-primary {
  color: black;
  background-color: white;
  border-color: white;
}
.app-nav-dropdown button {
  width: 196px !important;
  /* overflow-x: hidden; */
  overflow: hidden;
  height: 34px !important;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.app_repeat_dropdown .btn-primary:hover {
  border: 1px solid #c4c4c4 !important;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  background-color: var(--button-background-color) !important;
}
.newModal .modal-footer {
  margin: 0px 0px 0 0;
  border-top: 0px !important;
}
.app_repeat_dropdown .dropdown-menu-right {
  left: 89% !important;
}

#new-appts
  > div
  > div
  > div.sc-evZas.fRVrga.app_repeat_dropdown
  > div
  > button {
  background-color: #fff !important;
  color: #000000 !important;
  border: 1px solid rgba(34, 36, 38, 0.15);
}

.app_repeat_dropdown .btn-light {
  background-color: #fff !important;
  color: #000000 !important;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
#arrow-newAppt {
  /* background-image: url("../../assets/images/Down_Arrow.png"); */
  background-repeat: no-repeat;
  background-position: right 13px top 14px;
  line-height: 1;
}

.input_Wrapper {
  margin-left: 62px !important;
  margin-top: -5px !important;
}

.occurence_p {
  display: inline-flex !important;
  align-items: center !important;
  border: 1px solid #d9d9d9 !important;
  border-left: 0px !important;
  height: 36px !important;
  width: 84px !important;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-top: 1px;
}

.select_app_date1 .ant-input-number-input {
  height: 36px !important;
}

body
  > div.fade.newModal.modal.show
  > div
  > div
  > div.modal-body
  > div:nth-child(3)
  > div
  > div.rep-date2
  > label
  > span:nth-child(2) {
  display: flex;
}

.rep-date2 label {
  display: flex !important;
}
.input_Wrapper .ant-input-number-focused {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.select_app_date .ant-picker-suffix {
  opacity: 0;
}

.radio_appt_btn2 .ant-input-number:hover {
  border-color: #d9d9d9 !important;
  border-right-width: 1px !important;
}
/* .select_app_date1 .ant-input-number-handler-wrap {
  border-left: none;
  opacity: 4 !important;
  color: #000000 !important;
  background-image: url("../../assets/images/Up_Arrow and Down_Arrow1.png");

  background-repeat: no-repeat;
  background-position: right 2px top 3px;
  line-height: 1;
  height: 62%;
  top: 8px;
} */
.select_app_date1 .ant-input-number-handler-wrap {
  border-left: none;
  opacity: 0 !important;
  color: #00000000 !important;
  /* background-image: url("../../assets/images/Up_Arrow\ and\ Down_Arrow.png"); */

  background-repeat: no-repeat;
  background-position: right 3px top 2px;
  line-height: 1;
  height: 52%;
  top: 10px;
  right: 7px;
  z-index: 2;
}
.select_app_date1 .ant-input-number-handler-down {
  border-top: none;
}
.radio_appt_btn2 .ant-input-number-handler-up-inner svg {
  background-image: url("../../assets/images/Down_Arrow.png");
  background-repeat: no-repeat;
  background-position: right 13px top 14px;
  line-height: 1;
}
.radio_appt_btn2 .ant-picker {
  height: 38px !important;
}
.newModal .appt_repeats .btn-primary {
  color: #000000;
  background: none;
  border-color: #d9d9d9;
  width: 35px !important;
  margin: 0 8px 0 0 !important;
}
/* saranya repeat 20-04-23 */

/* saranya facility dropdown  27-04-23 */
/* // nisha Time picker start // */

.App-time {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  font-family: var(--font-work-sans);
  /* margin-left: 10px; */
}
.showTime {
  display: flex;
  justify-content: space-evenly;
  width: 130px;
  align-items: center;
}
.fromTime-toTime,
.time--space {
  font-family: var(--font-work-sans);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #2e2e2e;
  margin: 0px;
}

/* .time--space {
  font-weight: 400;
  font-size: 14px;
  
} */
/* // drop down // */
.fromArray-toArray {
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 130px;
  overflow-y: scroll;
  background-color: white !important;
  margin-top: 2px;
  position: absolute;
  z-index: 9999;
  top: 22px;
  border-radius: 5px;
  overflow-x: hidden;
  -webkit-box-shadow: 0px 0px 15px -5px rgba(158, 158, 158, 1);
  -moz-box-shadow: 0px 0px 15px -5px rgba(158, 158, 158, 1);
  box-shadow: 0px 0px 15px -5px rgba(158, 158, 158, 1);
}

.fromArray-toArray button {
  /* width: 70px; */

  border: none;
  outline: none;
  cursor: pointer;
  background-color: white !important;
  font-weight: 400;
  font-size: 14px;
  font-family: var(--font-work-sans);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 0px 6px 16px;
  width: 130px;
  height: 32px;
}

.fromArray-toArray button:hover {
  background-color: lightblue !important;
}

.fromArray_hide,
.toArray_hide {
  display: none;
}
/* width */
.fromArray-toArray::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.fromArray-toArray::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
.fromArray-toArray::-webkit-scrollbar-thumb {
  background: rgba(46, 46, 46, 0.5);
  border-radius: 100px;
  height: 50px;
}

.fromArray-toArray button:disabled,
.fromArray-toArray button[disabled] {
  color: #e5e5e5 !important;
}

/* // nisha end // */
.p-l-66 {
  padding-left: 66px !important;
}
.m-t--2 {
  margin-top: -2px !important;
}

.m-b-13 {
  margin-bottom: 13px !important;
}

.vital-bottom-btn.block {
  display: block;
}
.vital-bottom-btn.flex {
  display: flex;
}
.vital-bottom-btn {
  align-items: center;
  margin-top: 16px !important;
}
.vital-bottom-btn button {
  font-family: var(--font-work-sans);
  font-size: 14px;
  line-height: 19px;
  color: #2e2e2e;
}
.vital-bottom-btn > div {
  /* margin-right: 32px; */
  color: #2e2e2e;
  font-family: var(--font-work-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}
.adj-mdb-ht-scrll tbody {
  width: 100%;
}
/* .testing_tab tbody {
  display: flex;
} */
.testing_tab tbody tr {
  flex-grow: 1;
}
/* .adj-mdb-ht-scrll tr:first-child{
  width: 16%;
}
.adj-mdb-ht-scrll tr:nth-child(2){
  width: 18%;
}
.adj-mdb-ht-scrll tr:nth-child(3){
  width: 16%;
}
.adj-mdb-ht-scrll tr:nth-child(4){
  width: 12.975%;
} */
.new-apt-pat-sel {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.new_appt_dett .dropdiv .ant-select,
.new_appt_dett .dropdiv1 .ant-select {
  width: 202px !important;
}
.showTime input:focus-visible {
  outline: none;
}

.fromTime-toTime {
  width: 70px;
  border: 1px solid rgb(255, 255, 255);
  font-family: var(--font-work-sans);
}
.date-picker-width input {
  font-family: var(--font-work-sans);
}
#new_appt_radio {
  margin-top: 12px;
  margin-left: 20px;
}
.New-App_Mod-Body .wholediv {
  margin-left: 30px;
}
.appt_repeats label.btn {
  width: 30px;
  margin-right: 8px;
  border-radius: 4px !important;
  padding: 6px !important;
}
.up_arrow_custom {
  position: absolute;
  z-index: 1;
  left: 138px;
  top: 2px;
  font-size: 25px;
}
.down_arrow_custom {
  position: absolute;
  z-index: 1;
  left: 138px;
  top: 10px;
  font-size: 25px;
}
.ant-input-number.select_app_date1 {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
/*Ahamed_Changes_12-05-23*/
.new_appt_dett .showTime {
  width: 150px !important;
}
.vitalbutton.btn.btn-outline-secondary {
  color: var(--button-background-color) !important;
  border-color: var(--button-background-color);
  background-color: #fff;
}

.vitalbutton::before {
  content: " ";
  background-image: url("../../assets/images/vector2.png");
  width: 10.67px;
  height: 10.67px;
  display: inline-block;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  border: none;
  cursor: pointer;
  padding-left: 16px;
}
.vitalbutton.btn-outline-secondary:hover {
  border-color: var(--background-color);
  background-color: var(--background-color);
  color: #fff !important;
}
.vitalbutton.btn-outline-secondary:not(:disabled):not(.disabled):active {
  border-color: var(--button-background-color);
  background-color: var(--button-background-color);
  color: #fff !important;
}
.vital_history_heading {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px !important;
  margin-bottom: 8px;
  margin-right: 20px;
}
.bill-super-btn.btn-outline-secondary {
  width: 227px;
  height: 35px;
  background: rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(46, 46, 46, 0.25);
  color: #2e2e2e;
}
.down_arrow_doesnt {
  position: absolute;
  z-index: 1;
  font-size: 9px;
  margin-top: 13px;
  color: #636d73;
  right: 128px;
}
.down_custom_txt {
  position: absolute;
  z-index: 1;
  font-size: 9px;
  margin-top: 8px;
  color: #636d73;
  right: 11px;
}
#arrow-newApptadjust1 .ant-input-number:focus,
.ant-input-number-focused {
  border-color: unset;
  box-shadow: unset;
}
.ant-input-number.select_app_date1:focus {
  border: 1px solid lightgrey;
  box-shadow: unset;
}
.ant-input-number.select_app_date1:focus {
  border: none;
  box-shadow: unset;
}
.radio_appt_btn2 .ant-input-number {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
/* .rep-date2 .ant-input-number-focused {
  outline: none !important;
}*/
.rep-date2 input:focus {
  outline: none !important;
}
.bill-pay-bottom.disabled {
  cursor: not-allowed !important;
}
span.blue-color-inv.disabled,
.bill-add-pay-btn.disabled,
span.invoice-pointer.disabled,
.bill-crt-inv-btn.disabled {
  pointer-events: none !important;
}
.appt-full-popup .ant-collapse-header {
  background: white !important;
  font-family: var(--font-work-sans);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #2e2e2e;
}
.new_appt_detail:not(.appt-full-popup) .superbill_btn_marg {
  padding-top: 48px !important;
}
/* .appt-full-popup .superbill_btn_marg {
  padding-top: 30px !important;
} */
.new_appt_detail:not(.appt-full-popup) .m-t-24 {
  margin-top: 24px !important;
}
.appt-full-popup .m-t-24 {
  margin-top: 6px !important;
}
.appt-full-popup
  .ant-collapse-borderless
  > .ant-collapse-item
  > .ant-collapse-content {
  background: white !important;
}
.apt-det-bill-body select {
  height: 36px !important;
}
.apt-det-del-btn.disabled {
  cursor: not-allowed !important;
}
.new_appt_detail_footer .close-btn {
  border: 1px solid var(--button-background-color) !important;
  color: var(--button-background-color) !important;
  font-family: var(--font-work-sans) !important;
}
.new_appt_detail_footer .save-btn {
  font-family: var(--font-work-sans) !important;
}
.vit-tbl-first-row td {
  border-top: none !important;
}
.new_appt_detail_footer .save-btn.disabled {
  pointer-events: none !important;
}
/* saranya 19-05-23 */
.cal_facility_dropdown1 button {
  overflow: hidden !important;
  width: 122px !important;
  height: 34px !important;
  font-size: 14px;
  background-color: white !important;
  /* color: #2662f0 !important; */
  color: var(--button-background-color) !important;
  border: 1px solid #d9d9d9;
  text-align: left !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  font-family: var(--font-work-sans);
}
.cal_day_dropdown1 button {
  overflow: hidden !important;
  height: 35px !important;
  background-color: white !important;
  /* color: #2662f0 !important; */
  color: var(--button-background-color) !important;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 0px 24px 0px 12px;
  text-align: left !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  font-family: var(--font-work-sans);
}
/* saranya 19-05-23 */
/*Ahamed_18-05-2023*/
.aptDet_down_arrow_doesnt {
  position: absolute;
  z-index: 1;
  font-size: 9px;
  margin-top: 13px;
  color: #636d73;
  left: 227px;
}
/*Ahamed_18-05-2023*/
.vitalsModal tr.vit-tbl-first-row td.height {
  color: white !important;
}
.btn.btn-success.prac-sht-add-pay {
  color: white !important;
  padding-left: 10px !important;
}
/*Ahamed_23-05-2023*/
.showTimeProvAvl {
  width: 156px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  height: 32px;
  padding-left: 8px;
}
.App-timeProvAvl {
  align-items: start;
  justify-content: left;
  margin-left: 0px;
}
.down_arrow_doesnt.right_align_down {
  right: 194px;
}
.newModal.dialog-mrgn-auto {
  display: flex !important;
}
.newModal.dialog-mrgn-auto .modal-dialog {
  margin: auto;
}
.newModal.dialog-mrgn-auto .modal-dialog .modal-content {
  top: -3px;
}
/*Ahamed_23-05-2023*/
.ad-tk-pat-input {
  border: 1px solid red !important;
  border-radius: 5px !important;
}
.serv-edit-ndc-icon.disabled {
  pointer-events: none;
}
span.serv-edit-ndc-icon.disabled {
  cursor: not-allowed;
}
.remove-marg-right.for_autoComplete_bi {
  margin-right: 0px !important;
}
.w-221px.form-control {
  width: 221px !important;
  height: 36px !important;
  margin-right: 10px;
}
.ad-cont-err {
  color: red !important;
  width: max-content;
}
.pat_name_none_style {
  height: 3px;
  width: 16px;
  margin-left: 15px;
  background: var(--button-background-color);
}
.curs-not-insurance,
.curs-not-insurance:hover {
  cursor: not-allowed !important;
}

#adFam_sel-rel option {
  width: 10% !important;
}

/* Vanitha 22-11-23  */

.upgrade-req-modal {
  font-family: var(--font-work-sans) !important;
}
.upgrade-req-modal .modal-header {
  text-align: center !important;
  align-items: center !important;
}

.upgrade-req-modal .modal-title {
  margin-top: 0px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  font-family: var(--font-work-sans) !important;
  color: #2e2e2e !important;
}

.upgrade-req-modal .req-1-line1 {
  margin-bottom: 16px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: rgba(46, 46, 46, 0.75) !important;
  font-family: var(--font-work-sans) !important;
}

.upgrade-req-modal .req-1-line2 {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #2e2e2e !important;
  font-family: var(--font-work-sans) !important;
  line-height: normal !important;
}

.upgrade-req-modal .modal-header .close {
  padding: 2px 5px 5px 5px !important;
}

.upgrade-req-modal .req-1-line3 {
  font-family: var(--font-work-sans) !important;
  font-weight: 400 !important;
}
.st-arc-btn1disabled {
  cursor: not-allowed !important;
}

.st-arc-btn1enabled {
  cursor: pointer !important;
}
#add_payment .ant-picker {
  width: 37% !important;
  margin-left: 24px !important;
}

.modifiers-for-hcpcs-ndc .form-group {
  margin-right: 10px !important;
}

/* saranya */
.phar-middle-item1 {
  padding-left: 0px;
  padding-right: 16px;
}
.phar_drop_width {
  width: 100%;
}
/* saranya */
/*Ahamed 30-11-2023*/
.ant-sel-err-border .ant-select-selector,
.ant-pic-err-border {
  border: 1px solid red !important;
  border-radius: 5px !important;
}
.add-tsk-ant-dd .ant-select-arrow {
  width: unset;
}
.add-tsk-ant-dd .ant-select-arrow > img {
  width: 13px;
}
.ant-pic-hei34,
#ant-pic-hei34 .ant-picker,
.ant-sel-hei34 .ant-select-selector {
  height: 34px !important;
}
.ant-ellipsis-text .ant-select-selection-item {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 85px;
}
.ant-sel-ovr-ellip-text .ant-select-item-option-content {
  padding-left: 0 !important;
  text-overflow: unset;
  font-size: 12px;
}
.ant-sel-hei30 .ant-select-selector {
  height: 30px !important;
}
.tk-sel-box .ant-select-selector {
  border: none !important;
  width: 80px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  display: flex;
  align-items: center;
}
.tk-sel-box .tk-inp-pad-sml {
  width: 76px;
}
.tk-sel-box .ant-select-selection-item {
  color: #40cfe9 !important;
}
/*Ahamed 30-11-2023*/
/*Saranya 11-12-2023*/
.repeat-antd {
  margin-left: 10px;
  margin-right: 10px;
}
.repeat-antd .ant-select-selection-item {
  padding-right: 0px !important;
}
.repeat-antd .ant-select-selector {
  padding: 0 7px !important;
}
.repeat-antd .ant-select-arrow {
  display: none !important;
}
.repeat_every_app .form-control {
  border: none !important;
}
.repeat_every_app .repeat-antd-day {
  margin: 13px 10px 10px 10px;
}
.inv_form_relateto .ant-select-selector {
  border: none !important;
}
/*Saranya 11-12-2023*/

.w-266 {
  width: 266px !important;
}

.inv_background {
  background-color: #f1f5f8;
}
.task-right-nav {
  width: calc(100% - 64px) !important;
  height: max-content !important;
  padding-left: 16px !important;
  padding-bottom: 16px !important;
  margin-left: 64px !important;
}
.m-b-29 {
  margin-bottom: 29px !important;
}
.lab-res-print-md-bd.modal-body {
  height: calc(100vh - 55px) !important;
}
table.lab-res-border {
  border: 1px solid #cbd5e0;
}
.custom-disabled-option {
  cursor: not-allowed;
}

.rep_dateEnds {
  margin-bottom: 0px !important;
}

.vitalbutton1.btn.btn-outline-secondary {
  color: #28a745;
  background-color: #fff;
  border-color: #28a745;
}
.vitalbutton1::before {
  content: " ";
  background-image: url("../../assets/images/vector2.png");
  width: 10.67px;
  height: 10.67px;
  display: inline-block;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  border: none;
  cursor: pointer;
  padding-left: 16px;
}
.vitalbutton1.btn-outline-secondary:hover {
  border-color: hwb(117 26% 21%);
  background-color: hwb(117 26% 21%);
  color: #fff;
}
.vitalbutton1.btn-outline-secondary:not(:disabled):not(.disabled):active {
  border-color: hwb(117 26% 21%);
  background-color: hwb(117 26% 21%);
  color: #fff;
}

.vitalbutton1.btn-outline-secondary:hover {
  border-radius: 4px;
  color: #fff;
  border: 1px solid #28a745 !important;
  background: #28a745;
}
.m-l-92 {
  margin-left: 92px;
}
.endpargin {
  margin-bottom: 0px !important;
}
.new-appt-modal-height .modal-dialog {
  height: 680px !important;
}
.occ_input:focus {
  outline: none !important;
}
.occ_input1 .ant-input-number-input {
  border: 1px solid #d9d9d9;
  border-right: 0px;
  border-radius: 2px;
}
.speciality-option #clr-disable .ant-select-item-option-content {
  color: #cecece !important;
}
.fac-redstar {
  color: #e23d28 !important;
  font-size: 14px !important;
  padding-left: 0px !important;
}
.stateabsolute .rc-virtual-list-holder .div {
  position: absolute !important;
}
.staterelative {
  position: relative !important;
}
.tk-frmc-wid1.form-control,
.tk-frmc-wid1 .ant-select-selector {
  width: 244px !important;
}
.tk-frmc-wid2.form-control,
.tk-frmc-wid2 .ant-select-selector {
  width: 267px !important;
}
.task-right-nav1 {
  margin-left: 196px !important;
  width: calc(100% - 196px) !important;
}
.app-datepick .ant-picker {
  border: none !important;
  width: 120px;
  margin: 0px 0px 0px 1px;
}
.app-datepick .ant-picker:hover {
  border: none !important;
  width: 120px;
  margin: 0px 0px 0px 1px;
  background-color: white;
}

.layout-container {
  display: flex;
  /* flex-wrap: wrap !important; */
  /* flex-direction: column;  */
  width: 100% !important;
  /* gap: 16px;  */
}

.left-side {
  /* flex: 1 1 50%; 
  min-width: 300px; */
  box-sizing: border-box;
  border: 1px solid rgba(46, 46, 46, 0.25);
  margin: 10px;
  border-radius: 4px;
  width: 49%;
}

.right-side {
  /* flex: 1 1 50%; 
  min-width: 300px;  */
  box-sizing: border-box;
  border: 1px solid rgba(46, 46, 46, 0.25);
  margin: 10px;
  border-radius: 4px;
  width: 55%;
}

.appt_billing .ant-select-arrow {
  top: 50% !important;
}

/* Full width sections */
.full-width {
  width: 100%;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Responsive adjustments */
@media (max-width: 1267px) {
  .layout-container {
    flex-direction: column;
  }

  .right-side {
    width: 100%;
  }

  .left-side {
    width: 100%;
  }
}

.appt_heading {
  font-size: 14px;
  font-weight: 600;
  color: rgba(44, 123, 229, 1);
  padding: 10px;
}

.bill_superbill button {
  height: 28px !important;
  width: 200px !important;
  align-items: center;
  padding: 0px;
}

.appt_appt #customisedDatePicker input {
  padding-left: 0 !important;
}

#appt_superbill_btn {
  width: 200px !important;
  margin-right: 0;
}

.radio-label-container {
  display: flex;
  align-items: center;

  /* margin: 10px 0; */
  padding: 10px 0px 10px 10px;
}

.radio-label-container input[type="radio"] {
  margin-right: 8px;
  accent-color: #007bff;
}

.radio-label {
  font-size: 14px; /* Adjust as needed */
  color: #333; /* Adjust text color as needed */
  margin-bottom: 0;
}
.border_#cbd5e0 {
  border: 1px solid #cbd5e0 !important;
}

.appt_dlt_btn button {
  background: none !important;
  border: none !important;
}
.appt_Service_sec_table {
  border: 1px solid #cbd5e0 !important;
}

.appt_service th {
  padding: 6px 0 6px 16px !important;
}

.appt_service .bill-appt-pdb {
  padding: 10px !important;
}

.prob-head-right {
  position: relative;
}

/* .prob-head-right svg {
  margin-left: 7px;
  position: absolute;
  margin-top: 8px;
  opacity: 0.5;
} */

.appt_detail_diagnosis_code .ant-select-selector {
  width: 100% !important;
}

.ndc_button {
  border: none !important;
  background-color: #fff !important;
}

.vitals_table_body {
  display: flex;
}

.vitals_whole_div {
  border: 1px solid #cbd5e0 !important;
  padding: 10px;
  padding-bottom: 70px !important;
}

.appt_details_bottom {
  padding: 10px;
  align-items: center;
  padding-bottom: 20px;
}
.appt_appt .for-edit-app-insert-app .ant-picker {
  width: 202px !important;
}
.for_examRoom_number {
  width: 53.5% !important;
}
.label-for-minutes_exam {
  text-align: left;
  font-size: 15px;
  margin-right: 90px;
}
.for_display_wrap {
  flex-wrap: nowrap !important;
}

.disabled_edit_app {
  opacity: 0.5; /* Makes the div look disabled */
  cursor: not-allowed;
}
.disabled_edit_app_services {
  pointer-events: none;
  opacity: 0.5; /* Makes the div look disabled */
  cursor: not-allowed;
}
.cursor_style {
  cursor: pointer;
}
.vital-bottom-btn {
  display: flex !important;
}

.appt_details_bottom {
  width: 100% !important;
}
.for_font_size_h3 {
  font-size: 1.253rem;
}
.wel-row-bottom {
  padding-bottom: 1rem !important;
}
.wel_screen1 {
  max-width: 22% !important;
}
.wel_screen {
  display: flex;
  justify-content: center;
  align-items: center;
}
.weno_select
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  text-align: left;
}
.weno-label {
  border: none !important;
}
.weno-label span {
  color: #2c7be5 !important;
}
.weno-label .ant-upload-drag-container input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 415px;
  opacity: 0;
  cursor: pointer !important;
  width: 295px;
  height: 20px;
  margin: 2px 6px 15px 113px;
}
.weno-file-list {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 8px;
}

.weno-file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  background-color: #9fc0e0;
  color: #2c7be5;
  padding: 5px 5px 5px 5px;
}
.weno-drop {
  z-index: 1100;
}
.weno-file-item .up-d-icon-f {
  font-size: 20px;
  color: #2c7be5;
  margin-right: 5px;
  margin-top: 5px;
  height: 16px;
  width: 16px;
}
.weno-file-item .can-file {
  margin-top: 0px;
}
.weno-file-item .file-icon-bg {
  font-size: 16px;
  color: #2c7be5;
  width: 93%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
.weno-file-item-div {
  display: flex;
  max-width: 92%;
  text-align: left;
}
.textarea-placeholder::placeholder {
  color: #b0b0b0 !important;
}
.feedbackcrating {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 40px;
}
.feedbackcrating span {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 50px !important;
  cursor: pointer;
}
.rat-err {
  display: flex;
  justify-content: center;
}
.pro-custom-select-dropdown {
  z-index: 999 !important;
  position: relative;
}
.procedure-zindex .pro-custom-datepicker-dropdown {
  z-index: 999 !important;
}
.whatsnew_spin_zindex {
  z-index: 11 !important;
}
.cur-pointer {
  cursor: pointer;
}

.appt_details_bottom svg{
  margin-right: 20px !important;
}
